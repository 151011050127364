import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import mapBG from '../assets/images/map-bg.jpg';
import InputAdornment from "@mui/material/InputAdornment";
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import offerCloseIcon from '../assets/svg/modal-close-icon-grey.svg';
import carParkPin from '../assets/svg/carpark-pin-sml.svg';
import carParkImage from '../assets/images/carpark-image-upark.jpg';


const useStyles = makeStyles((theme) => ({
	  root: {
		flexGrow: 1,
	  },
		nvpButton: {
			fontFamily: 'Nunito Sans',
			fontWeight: 'bold',
			textTransform: 'none',
			color: 'white',
			fontSize: '1.063rem',
		},
		gridNoMargin: {
			paddingTop: '0 !important',
			paddingBottom: '0 !important',
		},
		gridNoBottomMargin: {
			  paddingBottom: '0 !important',
		},
		gridNoTopMargin: {
			paddingTop: '0 !important',
		},
		wrapperMargin: {
			paddingTop: '20px',
		},
		mapBG: {
			width: '100vw',
			height: '100vh',
			spacing: 0,
			backgroundColor: '#e8e8e8',
			paddingTop: '110px',
			paddingBottom: '0',
			backgroundImage: `url(${mapBG})`
		},
		bottomDrawer: {
			background: 'white',
			padding: '25px 25px 50px 25px',
			borderTopLeftRadius: '5px',
			borderTopRightRadius: '5px',
			position: 'absolute',
			bottom: '0',
			width: 'calc(100% - 82px)!important',
		}
	}));

export default function CenteredGrid() {
	  const classes = useStyles();
	return <>
		<Container className="overlayBG">
			<Box className={classes.bottomDrawer}>
				<Grid container spacing={2}>
					<Grid item xs={12} style={{ paddingTop: '10px' }}>
						<a className="offer-close-button" href="#"><img className="offer-close-icon" src={offerCloseIcon} alt="Close" /></a>
						<p className="carpark-details"><strong>457 Franklin</strong> (ID. 47235)<br />
						<span style={{ fontSize: '1rem' }}><img className="car-park-pin" src={carParkPin} alt="Pin" />457 Franklin St, Adelaide 5000</span></p>
					</Grid>
					<Grid item xs={12} style={{ paddingTop: '7px' }}>
						<img className="carpark-image" src={carParkImage} alt="Car Park Image" />
					</Grid>
				</Grid>
			</Box>
		</Container>
		<div id="header-wrapper">
		  <div id="header">
				<h1 className="icon-h1 carpark-providers">Carpark Providers</h1>
		   </div>
		 </div>
		<Container className={classes.mapBG}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Select className="validoSelect"
						IconComponent={ExpandMoreIcon}
						  MenuProps={{
							anchorOrigin: {
							  vertical: "bottom",
							  horizontal: "left"
							},
							transformOrigin: {
							  vertical: "top",
							  horizontal: "left"
							},
					
						  }}
					  value="10" variant="outlined" margin="dense" fullWidth>
						<MenuItem value="10" className="selectBold">Carpark</MenuItem>
						<MenuItem value="20">Carpark option 2</MenuItem>
						<MenuItem value="30">Carpark option 3</MenuItem>
						<MenuItem value="40">Carpark option 4</MenuItem>
					 </Select>
				</Grid>
			</Grid>
			<Box>
				<div className="save-favourite"></div>
			</Box>
		</Container>
		<div id="footer-wrapper">
			
			<div id="footer">
				<a className="footer-icon" id="footer-icon-home" href="#"></a>
				<a className="footer-icon" id="footer-icon-arrow-l" href="/six"></a>
				<a className="footer-icon" id="footer-icon-arrow-r" href="/eight"></a>
				<a className="footer-icon" id="footer-icon-menu" href="#"></a>
			</div>
					
		</div>
	</>
}
