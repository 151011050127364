import makeStyles from '@mui/styles/makeStyles';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import offerCloseIcon from '../assets/svg/modal-close-icon-grey.svg';


const useStyles = makeStyles((theme) => ({
		root: {
			flexGrow: 1,
		},
		nvpButton: {
			fontFamily: 'Nunito Sans',
			fontWeight: 'bold',
			textTransform: 'none',
			color: 'white',
			fontSize: '1.063rem',
		},
		gridNoMargin: {
			paddingTop: '0 !important',
			paddingBottom: '0 !important',
		},
		gridNoBottomMargin: {
			paddingBottom: '0 !important',
		},
		gridNoTopMargin: {
			paddingTop: '0 !important',
		},
		wrapperMargin: {
			paddingTop: '20px',
		},
		drawBG: {
			width: '100vw',
			height: '100vh',
			spacing: 0,
			justify: 'space-around',
			backgroundColor: '#4b4b4b',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
		},
		bottomDrawer: {
			background: 'white',
			padding: '25px 25px 60px 25px',
			borderTopLeftRadius: '5px',
			borderTopRightRadius: '5px',
			position: 'absolute',
			bottom: '0',
			width: 'calc(100% - 82px)!important',
		}
	}));

export default function CenteredGrid() {
		  const classes = useStyles();
		return <>
				<Container className={classes.drawBG}>
					<Box className={classes.bottomDrawer}>
						<Grid container spacing={2}>
							<Grid item xs={12} style={{ paddingTop: '10px' }}>
								<a className="offer-close-button" href="#"><img className="offer-close-icon" src={offerCloseIcon} alt="Close" /></a>
								<h2 className="icon-h2 terms-of-use no-bottom-padding">Terms of use</h2>
							</Grid>
							<Grid item xs={12} style={{ paddingBottom: '35px' }}>
								<FormGroup>
									<FormControlLabel control={<Checkbox defaultChecked />} style={{ paddingBottom: '15px' }} label="I have read and accept the Valido Privacy Policy & Terms of Use" />
									<FormControlLabel control={<Checkbox defaultChecked />} style={{ paddingBottom: '15px' }} label="I acknowledge that Ticket Display and Metered Cash Parking are not supported by Valido" />
									<FormControlLabel control={<Checkbox defaultChecked />} style={{ paddingBottom: '15px' }} label="I acknowledge that only Participating Parking Providers are supported by Valido" />
								</FormGroup>
							</Grid>
							<Grid item xs={6}>
									<Button className={classes.nvpButton} variant="contained" color="secondary" size="large" disableElevation>No</Button>
								</Grid>
								<Grid item xs={6}>
									<Button className={classes.nvpButton} variant="contained" color="secondary" size="large" disabled disableElevation>Yes</Button>
								</Grid>
						</Grid>
					</Box>
				</Container>
		</>
	}