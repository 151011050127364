import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';


const useStyles = makeStyles((theme) => ({
	  root: {
		flexGrow: 1,
	  },
		nvpButton: {
			fontFamily: 'Nunito Sans',
			fontWeight: 'bold',
			textTransform: 'none',
			color: 'white',
			fontSize: '1.063rem',
		},
		gridNoBottomMargin: {
			  paddingBottom: '0 !important',
		},
		gridNoTopMargin: {
			paddingTop: '0 !important',
		},
		wrapperMargin: {
			paddingTop: '20px',
		},
	}));

export default function CenteredGrid() {
      const classes = useStyles();
    return <>
		<div id="header-wrapper">
		  <div id="header">
				<h1 className="no-icon parker-id">Parker ID QS00172 <br /> 
				<span className="subtitle">457 Franklin Street</span></h1>
				<a id="valido-icon" href="#"></a>
		   </div>
		 </div>
		<div id="main">
    	<Container maxWidth="lg">
			<Grid container spacing={2}>
				<Grid item xs={12} style={{ paddingTop: '0' }}>
					<h2 className="icon-h2 free-parking-earned">Total FREE parking <strong className="free-parking-earned">0hrs 00min</strong></h2>
				</Grid>
			</Grid>
			<Grid container spacing={2} className="network-link-wrapper">
				<Grid item xs={12}>
					<p><a className="network-link" href="#">View Valido Carpark &amp; Merchant Network</a></p>
				</Grid>
			</Grid>
			<Grid container spacing={2} className="welcome-text-block">
				<Grid item xs={12}>
					<h3>Welcome to Valido</h3>
					<p>Earn <strong>Free Parking</strong> when you <br /> Shop, Dine &amp; Play!</p>
				</Grid>
			</Grid>
		</Container>
		<Container maxWidth="lg" className="buttonWrapperSticky">
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<p className="centered-text fix-mobile-wrap">Select <strong>Record Purchase</strong> to start earning free parking.</p>
				</Grid>
				<Grid item xs={6} style={{ paddingTop: '0' }}>
					<Button className="entryButtonSmall record-purchase" variant="contained" color="secondary" size="large" disableElevation>Record Purchase</Button>
				</Grid>
				<Grid item xs={6} style={{ paddingTop: '0' }}>
					<Button className="entryButtonSmall claim-parking" variant="contained" color="secondary" size="large" disableElevation>Claim Free Parking</Button>
				</Grid>
			</Grid>
		</Container>
		</div>
		<div id="footer-wrapper">
			
			<div id="footer">
				<a className="footer-icon" id="footer-icon-home" href="#"></a>
				<a className="footer-icon" id="footer-icon-arrow-l" href="/nine"></a>
				<a className="footer-icon" id="footer-icon-arrow-r" href="/eleven"></a>
				<a className="footer-icon" id="footer-icon-menu" href="#"></a>
			</div>
					
		</div>
    </>
}
