import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import qrScanBG from '../assets/images/qr-scan-box-bg.png';


const useStyles = makeStyles((theme) => ({
	  root: {
		flexGrow: 1,
	  },
		nvpButton: {
			fontFamily: 'Nunito Sans',
			fontWeight: 'bold',
			textTransform: 'none',
			color: 'white',
			fontSize: '1.063rem',
		},
		gridNoBottomMargin: {
			  paddingBottom: '0 !important',
		},
		gridNoTopMargin: {
			paddingTop: '0 !important',
		},
		wrapperMargin: {
			paddingTop: '20px',
		},
		scanBoxBG: {
			backgroundColor: '#eff2f4',
			textAlign: 'center',
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'center',
			alignItems: 'center',
			marginTop: '18px!important',
			marginLeft: '16px!important',
			paddingRight: '16px',
			paddingTop: '40px!important',
			paddingBottom: '40px',
		},
		cardBG: {
			backgroundColor: '#fd5a5f!important',
			backgroundImage: `url(${qrScanBG})`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
			backgroundSize: '80%',
			padding: '25px',
			width: 'auto',
			height: '46%',
			minHeight: '130px',
			borderRadius: '10px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			flexDirection: 'row',
		},
		cardContent: {
			
		}
	}));

export default function CenteredGrid() {
      const classes = useStyles();
    return <>
		<div id="header-wrapper">
		  <div id="header">
				<h1 className="no-icon parker-id">Parker ID QS00172 <br /> 
				<span className="subtitle">Metro City Council</span></h1>
				<a id="valido-icon" href="#"></a>
		   </div>
		 </div>
		<div id="main">
    	<Container maxWidth="lg">
			<Grid container spacing={2}>
				<Grid item xs={12} style={{ paddingTop: '10px' }}>
					<h2 className="icon-h2 cashier-code">Cashier Code</h2>
					<p className="fix-mobile-wrap">Enter the <a href="#">Cashier ID</a> or scan the <a href="#">Cashier's QR Code.</a></p>
				</Grid>
				<Grid item xs={12} className={classes.gridNoTopMargin}>
					<TextField inputProps={{ className: "nvpInput" }} id="outlined-basic" label="Cashier ID" InputLabelProps={{ className: "nvpLabel" }} variant="outlined" margin="dense" fullWidth />
				</Grid>
				<Grid item xs={12} className={classes.scanBoxBG}>
					<Card className={classes.cardBG}>
						<CardContent className={classes.cardContent}>
							<p className="scan-box-text">Tap to scan <br /> Cashier QR Code</p>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</Container>
		<Container maxWidth="lg" className="buttonWrapperSticky">
			<Grid container spacing={2}>
				<Grid item xs={6}>
					<Button className={classes.nvpButton} variant="contained" color="secondary" size="large" disableElevation>Back</Button>
				</Grid>
				<Grid item xs={6}>
					<Button className={classes.nvpButton} variant="contained" color="secondary" size="large" disabled disableElevation>Next</Button>
				</Grid>
			</Grid>
		</Container>
		</div>
		<div id="footer-wrapper">
			
			<div id="footer">
				<a className="footer-icon" id="footer-icon-home" href="#"></a>
				<a className="footer-icon" id="footer-icon-arrow-l" href="#"></a>
				<a className="footer-icon" id="footer-icon-arrow-r" href="#"></a>
				<a className="footer-icon" id="footer-icon-menu" href="#"></a>
			</div>
					
		</div>
    </>
}
