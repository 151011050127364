import makeStyles from '@mui/styles/makeStyles';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import imageMyer from '../assets/images/vendor-image-myer.jpg';
import logoMyer from '../assets/images/vendor-logo-myer.jpg';
import offerCloseIcon from '../assets/svg/modal-close-icon-grey.svg';


const useStyles = makeStyles((theme) => ({
	  root: {
		flexGrow: 1,
	  },
	  gridNoMargin: {
		  paddingTop: '0 !important',
		  paddingBottom: '0 !important',
	  },
		gridNoBottomMargin: {
			  paddingBottom: '0 !important',
		},
		gridNoTopMargin: {
			paddingTop: '0 !important',
		},
		wrapperMargin: {
			paddingTop: '20px',
		},
	}));

export default function CenteredGrid() {
      const classes = useStyles();
    return <>
		<div id="header-wrapper" className="no-page-title">
			  <div id="header">
				
			   </div>
			   <div className="fixed-title-wrapper"></div>
			 </div>
			<div id="main" className="no-header">
			<Container maxWidth="lg" className="parking-offers-wrapper">
				<Box bgcolor="white" display="flex">
					<Grid container spacing={2}>
						<Grid item xs={12} style={{ paddingTop: '10px' }}>
							<a className="offer-close-button" href="#"><img className="offer-close-icon" src={offerCloseIcon} alt="Close" /></a>
							<h2 className="icon-h2 terms-conditions">Terms &amp; Conditions</h2>
						</Grid>
						<Grid item xs={12} className={classes.gridNoMargin}>
							<p className="vendor-description">Terms and Conditions to be added.</p>
						</Grid>
					</Grid>
				</Box>
			</Container>
			</div>
    </>
}
