import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import qrScanning from '../assets/images/qr-scanning-dummy.png';

const useStyles = makeStyles((theme) => ({
	  root: {
		flexGrow: 1,
	  },
		nvpButton: {
			fontFamily: 'Nunito Sans',
			fontWeight: 'bold',
			textTransform: 'none',
			color: 'white',
			fontSize: '1.063rem',
		},
		gridNoBottomMargin: {
			  paddingBottom: '0 !important',
		},
		gridNoTopMargin: {
			paddingTop: '0 !important',
		},
		wrapperMargin: {
			paddingTop: '20px',
		},
		cameraBoxBG: {
			backgroundColor: '#999999',
			backgroundImage: `url(${qrScanning})`,
			backgroundSize: '60%',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center center',
			textAlign: 'center',
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'center',
			alignItems: 'center',
			minHeight: '280px',
			marginTop: '18px!important',
			marginLeft: '16px!important',
		},
	}));

export default function CenteredGrid() {
      const classes = useStyles();
    return <>
		<div id="header-wrapper">
		  <div id="header">
				<h1 className="no-icon parker-id">Parker ID QS00172 <br /> 
				<span className="subtitle">457 Franklin Street</span></h1>
				<a id="valido-icon" href="#"></a>
		   </div>
		 </div>
		<div id="main">
    	<Container maxWidth="lg">
			<Grid container spacing={2}>
				<Grid item xs={12} style={{ paddingTop: '10px' }}>
					<h2 className="icon-h2 cashier-code">Cashier Code</h2>
					<p className="fix-mobile-wrap">Enter the <a href="#">Cashier ID</a> or scan the <a href="#">Cashier's QR Code.</a></p>
				</Grid>
				<Grid item xs={12} className={classes.gridNoTopMargin}>
					<TextField inputProps={{ className: "nvpInput" }} id="outlined-basic" label="Cashier ID" InputLabelProps={{ className: "nvpLabel" }} variant="outlined" margin="dense" fullWidth />
				</Grid>
				<Grid item xs={12} className={classes.cameraBoxBG}>
					
				</Grid>
			</Grid>
		</Container>
		<Container maxWidth="lg" className="buttonWrapperSticky">
			<Grid container spacing={2}>
				<Grid item xs={6}>
					<Button className={classes.nvpButton} variant="contained" color="secondary" size="large" disableElevation>Back</Button>
				</Grid>
				<Grid item xs={6}>
					<Button className={classes.nvpButton} variant="contained" color="secondary" size="large" disableElevation>Next</Button>
				</Grid>
			</Grid>
		</Container>
		</div>
		<div id="footer-wrapper">
			
			<div id="footer">
				<a className="footer-icon" id="footer-icon-home" href="#"></a>
				<a className="footer-icon" id="footer-icon-arrow-l" href="/twentysix"></a>
				<a className="footer-icon" id="footer-icon-arrow-r" href="/twentyeight"></a>
				<a className="footer-icon" id="footer-icon-menu" href="#"></a>
			</div>
					
		</div>
    </>
}
