import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import carparkIcearena from '../assets/images/car-park-img-icearena.jpg';
import carparkAdelairport from '../assets/images/car-park-img-adelairport.jpg';
import carparkFranklin from '../assets/images/car-park-img-franklinst.jpg';
import carparkTophammall from '../assets/images/car-park-img-tophammall.jpg';
import carparkCentralmarket from '../assets/images/car-park-img-centralmarket.jpg';
import deleteIconSml from '../assets/images/delete-icon-sml.png';
import locationPinSml from '../assets/svg/my-parks-pin-icon.svg';


const useStyles = makeStyles((theme) => ({
	  root: {
		flexGrow: 1,
	  },
		nvpButton: {
			fontFamily: 'Nunito Sans',
			fontWeight: 'bold',
			textTransform: 'none',
			color: 'white',
			fontSize: '1.063rem',
		},
		gridNoMargin: {
				padding: '0 !important',
			},
		gridNoBottomMargin: {
			  paddingBottom: '0 !important',
		},
		gridNoTopMargin: {
			paddingTop: '0 !important',
		},
		gridNoTopBottomMargin: {
				paddingTop: '0 !important',
				paddingBottom: '0 !important',
			},
		wrapperMargin: {
			paddingTop: '20px',
		},
	}));

export default function CenteredGrid() {
      const classes = useStyles();
    return <>
		<div id="header-wrapper">
		  <div id="header">
				<h1 className="icon-h1 my-parks">My Parks</h1>
				<a id="valido-logo" href="#"></a>
		   </div>
		 </div>
		<div id="main" className="no-top-margin">
    	<Container maxWidth="lg" className={classes.gridNoMargin}>
			<Grid container spacing={0} className="my-car-parks-block">
				<Grid item xs={12} className={classes.gridNoTopBottomMargin}>
					<img className="car-park-img" src={carparkCentralmarket} alt="Central Market" />
					<div className="car-park-title">
						<h4>Upark Central Market</h4>
						<a className="delete-my-park" href="#"><img className="delete-icon-sml" src={deleteIconSml} alt="Delete" /></a>
					</div>
					<div className="merchant-location">
						<p><a className="merchant-location-link" href="#"><img className="merchant-location-pin" src={locationPinSml} alt="Location" /></a>372 Grote St, Adelaide 5000</p>
					</div>
				</Grid>
			</Grid>
			<Grid container spacing={0} className="my-car-parks-block">
				<Grid item xs={12} className={classes.gridNoTopBottomMargin}>
					<img className="car-park-img" src={carparkTophammall} alt="Topham Mall" />
					<div className="car-park-title">
						<h4>Upark Topham Mall</h4>
						<a className="delete-my-park" href="#"><img className="delete-icon-sml" src={deleteIconSml} alt="Delete" /></a>
					</div>
					<div className="merchant-location">
						<p><a className="merchant-location-link" href="#"><img className="merchant-location-pin" src={locationPinSml} alt="Location" /></a>52/54 Waymouth St, Adelaide 5000</p>
					</div>
				</Grid>
			</Grid> 
			<Grid container spacing={0} className="my-car-parks-block">
				<Grid item xs={12} className={classes.gridNoTopBottomMargin}>
					<img className="car-park-img" src={carparkFranklin} alt="Franklin St" />
					<div className="car-park-title">
						<h4>Upark 457 Franklin</h4>
						<a className="delete-my-park" href="#"><img className="delete-icon-sml" src={deleteIconSml} alt="Delete" /></a>
					</div>
					<div className="merchant-location">
						<p><a className="merchant-location-link" href="#"><img className="merchant-location-pin" src={locationPinSml} alt="Location" /></a>457 Franklin St, Adelaide 5000</p>
					</div>
				</Grid>
			</Grid> 
			<Grid container spacing={0} className="my-car-parks-block">
				<Grid item xs={12} className={classes.gridNoTopBottomMargin}>
					<img className="car-park-img" src={carparkIcearena} alt="Topham Mall" />
					<div className="car-park-title">
						<h4>Adelaide Ice Arena</h4>
						<a className="delete-my-park" href="#"><img className="delete-icon-sml" src={deleteIconSml} alt="Delete" /></a>
					</div>
					<div className="merchant-location">
						<p><a className="merchant-location-link" href="#"><img className="merchant-location-pin" src={locationPinSml} alt="Location" /></a>23 James Congdon Dr, Thebarton</p>
					</div>
				</Grid>
			</Grid> 
		</Container>
		<Container maxWidth="lg" className="buttonWrapperSticky">
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Button className={classes.nvpButton} variant="contained" color="secondary" size="large" disableElevation>Done</Button>
				</Grid>
			</Grid>
		</Container>
		</div>
		<div id="footer-wrapper">
			
			<div id="footer">
				<a className="footer-icon" id="footer-icon-home" href="#"></a>
				<a className="footer-icon" id="footer-icon-arrow-l" href="#"></a>
				<a className="footer-icon" id="footer-icon-arrow-r" href="#"></a>
				<a className="footer-icon" id="footer-icon-menu" href="#"></a>
			</div>
					
		</div>
    </>
}
