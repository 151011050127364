import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import verifyMobileIcon from '../assets/svg/icon-verify-mobile.svg';
import gatedParkIcon from '../assets/svg/icon-button-gated-carpark.svg';
import mobileAppIcon from '../assets/svg/icon-button-mobile-app.svg';
import parkingMeterIcon from '../assets/svg/icon-button-credit-card.svg';


const useStyles = makeStyles((theme) => ({
	  root: {
		flexGrow: 1,
	  },
		gatedParkingButton: {
			backgroundImage: `url(${gatedParkIcon})`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: '10px center',
			backgroundSize: '37px',
			backgroundColor: '#ffffff!important',
			borderWidth: '2px!important',
			borderColor: '#FD5A5F!important',
			color: '#FD5A5F',
			marginTop: '7px!important',
			padding: '20px 20px 20px 50px!important',
			justifyContent: 'flex-start!important',
			fontSize: '1.25rem!important',
		},
		mobileParkingButton: {
			backgroundImage: `url(${mobileAppIcon})`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: '10px center',
			backgroundSize: '32px',
			backgroundColor: '#ffffff!important',
			borderWidth: '2px!important',
			borderColor: '#FD5A5F!important',
			color: '#FD5A5F',
			marginTop: '7px!important',
			padding: '20px 20px 20px 50px!important',
			justifyContent: 'flex-start!important',
			fontSize: '1.25rem!important',
		},
		meterParkingButton: {
			backgroundImage: `url(${parkingMeterIcon})`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: '10px center',
			backgroundSize: '20px',
			backgroundColor: '#ffffff!important',
			borderWidth: '2px!important',
			borderColor: '#FD5A5F!important',
			color: '#FD5A5F',
			marginTop: '7px!important',
			padding: '20px 20px 20px 50px!important',
			textAlign: 'left',
			fontSize: '1.25rem!important',
			lineHeight: '1.5rem!important',
		},
		nvpButton: {
			fontFamily: 'Nunito Sans',
			fontWeight: 'bold',
			textTransform: 'none',
			color: 'white',
			fontSize: '1.063rem',
		},
		gridNoBottomMargin: {
			  paddingBottom: '0 !important',
		},
		gridNoTopMargin: {
			paddingTop: '0 !important',
		},
		wrapperMargin: {
			paddingTop: '20px',
		},
	}));

export default function CenteredGrid() {
      const classes = useStyles();
    return <>
		<div id="header-wrapper">
		  <div id="header">
				<h1 className="icon-h1 parking-method">Parking Method</h1>
		   </div>
		 </div>
		<div id="main">
    	<Container maxWidth="lg">
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<h3 className="icon-h3 how-did-you-park">How did you park?</h3>
					<h4 className="icon-h4 important-warning">IMPORTANT: The Valido Reward Program does not support <a href="#">Display Ticket</a> and <a href="#">Metered Cash parking</a></h4>
				</Grid>
				<Grid item xs={12} className="mobile-button-override">
					<Button className={classes.gatedParkingButton} variant="outlined" color="secondary" size="large" disableElevation>I parked in a gated carpark</Button>
				</Grid>
				<Grid item xs={12} className="mobile-button-override">
					<Button className={classes.mobileParkingButton} variant="outlined" color="secondary" size="large" disableElevation>I used a 'Mobile Phone App'</Button>
				</Grid>
				<Grid item xs={12} className="mobile-button-override">
					<Button className={classes.meterParkingButton} variant="outlined" color="secondary" size="large" disableElevation>I used my credit card &amp; entered my license plate on a parking meter</Button>
				</Grid>
			</Grid>
		</Container>
		
		</div>
		<div id="footer-wrapper">
			
			<div id="footer">
				<a className="footer-icon" id="footer-icon-home" href="#"></a>
				<a className="footer-icon" id="footer-icon-arrow-l" href="/nineteen"></a>
				<a className="footer-icon" id="footer-icon-arrow-r" href="/twentyone"></a>
				<a className="footer-icon" id="footer-icon-menu" href="#"></a>
			</div>
					
		</div>
    </>
}
