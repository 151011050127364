import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import mapBGSearch from '../assets/images/map-bg-search.jpg';
import InputAdornment from "@mui/material/InputAdornment";
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";


const useStyles = makeStyles((theme) => ({
	  root: {
		flexGrow: 1,
	  },
		nvpButton: {
			fontFamily: 'Nunito Sans',
			fontWeight: 'bold',
			textTransform: 'none',
			color: 'white',
			fontSize: '1.063rem',
		},
		gridNoBottomMargin: {
			  paddingBottom: '0 !important',
		},
		gridNoTopMargin: {
			paddingTop: '0 !important',
		},
		wrapperMargin: {
			paddingTop: '20px',
		},
		mapBG: {
			width: '100vw',
			height: '100vh',
			spacing: 0,
			backgroundColor: '#e8e8e8',
			paddingTop: '110px',
			paddingBottom: '0',
			backgroundImage: `url(${mapBGSearch})`
		},
	}));

export default function CenteredGrid() {
      const classes = useStyles();
    return <>
		<div id="header-wrapper">
		  <div id="header">
				<h1 className="icon-h1 carpark-providers">Carpark Search</h1>
		   </div>
		 </div>
    	<Container className={classes.mapBG}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					
				</Grid>
			</Grid>
			<Box>
				<div className="save-favourite"></div>
			</Box>
		</Container>
		<div id="footer-wrapper">
			
			<div id="footer">
				<a className="footer-icon" id="footer-icon-home" href="#"></a>
				<a className="footer-icon" id="footer-icon-arrow-l" href="/eight"></a>
				<a className="footer-icon" id="footer-icon-arrow-r" href="/ten"></a>
				<a className="footer-menu" id="footer-icon-user" href="#"></a>
			</div>
					
		</div>
    </>
}
