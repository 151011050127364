import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import processingGraphic from '../assets/svg/processing-graphic.svg';


const useStyles = makeStyles((theme) => ({
	  root: {
		flexGrow: 1,
	  },
		nvpButton: {
			fontFamily: 'Nunito Sans',
			fontWeight: 'bold',
			textTransform: 'none',
			color: 'white',
			fontSize: '1.063rem',
		},
		gridNoBottomMargin: {
			  paddingBottom: '0 !important',
		},
		gridNoTopMargin: {
			paddingTop: '0 !important',
		},
		wrapperMargin: {
			paddingTop: '20px',
		},
	}));

export default function CenteredGrid() {
      const classes = useStyles();
    return <>
		<div id="header-wrapper">
		  <div id="header">
				<h1 className="icon-h1 processing-payment">Processing Payment</h1>
		   </div>
		 </div>
		<div id="main">
    	<Container maxWidth="lg">
			<Grid container spacing={2} className="success-text-block">
				<Grid item xs={12} style={{ paddingTop: '100px' }}>
					<img className="processing-graphic" src={processingGraphic} alt="Processing" />
					<h4 style={{ paddingTop: '60px' , paddingBottom: '30px' }}>Processing...</h4>
					<p className="processing-text">Please wait as we process your payment.</p>
				</Grid>
			</Grid>
		</Container>
		</div>
		<div id="footer-wrapper">
			
			<div id="footer">
				<a className="footer-icon" id="footer-icon-home" href="#"></a>
				<a className="footer-icon" id="footer-icon-arrow-l" href="#"></a>
				<a className="footer-branding" id="footer-icon-logo" href="#"></a>
				<a className="footer-icon" id="footer-icon-arrow-r" href="#"></a>
				<a className="footer-icon" id="footer-icon-menu" href="#"></a>
			</div>
					
		</div>
    </>
}
