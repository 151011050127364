import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import successIcon from '../assets/images/success-icon.png';
import receiptIcon from '../assets/svg/button-icon-receipt-white.svg';


const useStyles = makeStyles((theme) => ({
	  root: {
		flexGrow: 1,
	  },
		nvpButton: {
			fontFamily: 'Nunito Sans',
			fontWeight: 'bold',
			textTransform: 'none',
			color: 'white',
			fontSize: '1rem!important',
		},
		nvpIconButton: {
			backgroundImage: `url(${receiptIcon})`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: '12% center',
			fontFamily: 'Nunito Sans',
			fontWeight: 'bold',
			textTransform: 'none',
			color: 'white',
			fontSize: '1rem!important',
			textIndent: '30px',
		},
		gridNoBottomMargin: {
			  paddingBottom: '0 !important',
		},
		gridNoTopMargin: {
			paddingTop: '0 !important',
		},
		wrapperMargin: {
			paddingTop: '20px',
		},
	}));

export default function CenteredGrid() {
      const classes = useStyles();
    return <>
		<div id="header-wrapper">
		  <div id="header">
				<h1 className="icon-h1 payment-successful">Payment Successful</h1>
		   </div>
		 </div>
		<div id="main">
    	<Container maxWidth="lg">
			<Grid container spacing={2} className="success-text-block">
				<Grid item xs={12}>
					<h4>Your parking has stopped</h4>
					<p className="parking-has-stopped">You must leave the carpark in 5 minutes or you may incur additional charges.</p>
					<img className="success-icon" src={successIcon} alt="Close" style={{ paddingTop: '30px' , paddingBottom: '20px' }} />
					<h4 style={{ paddingBottom: '30px' }}>Payment complete</h4>
					<p className="parking-has-stopped">Do you wich to receive an email receipt?</p>
				</Grid>
				<Grid item xs={12} style={{ paddingTop: '0' }}>
					<TextField inputProps={{ className: "nvpInput" }} id="outlined-basic" label="Email" InputLabelProps={{ className: "nvpLabel" }} variant="outlined" margin="dense" fullWidth />
				</Grid>
			</Grid>
		</Container>
		<Container maxWidth="lg" className="buttonWrapperSticky">
			<Grid container spacing={2}>
				<Grid item xs={6}>
					<Button className={classes.nvpButton} variant="contained" color="secondary" size="large" disableElevation>No Thanks</Button>
				</Grid>
				<Grid item xs={6}>
					<Button className={classes.nvpIconButton} variant="contained" color="secondary" size="large" disabled disableElevation>Send Receipt</Button>
				</Grid>
			</Grid>
		</Container>
		</div>
		<div id="footer-wrapper">
			
			<div id="footer">
				<a className="footer-icon" id="footer-icon-home" href="#"></a>
				<a className="footer-icon" id="footer-icon-arrow-l" href="#"></a>
				<a className="footer-branding" id="footer-icon-logo" href="#"></a>
				<a className="footer-icon" id="footer-icon-arrow-r" href="#"></a>
				<a className="footer-icon" id="footer-icon-menu" href="#"></a>
			</div>
					
		</div>
    </>
}
