import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import profileCameraIcon from '../assets/svg/profile-icon-camera.svg';
import profileTrashIcon from '../assets/svg/profile-icon-trash.svg';
import profileAvatar from '../assets/images/profile-tony-roberts.jpg';

const useStyles = makeStyles((theme) => ({
	  root: {
		flexGrow: 1,
	  },
		nvpButton: {
			fontFamily: 'Nunito Sans',
			fontWeight: 'bold',
			textTransform: 'none',
			color: 'white',
			fontSize: '1.063rem',
		},
		gridNoMargin: {
			padding: '0 !important',
		},
		gridNoBottomMargin: {
			paddingBottom: '0 !important',
		},
		gridNoTopMargin: {
			paddingTop: '0 !important',
		},
		gridNoTopBottomMargin: {
			paddingTop: '0 !important',
			paddingBottom: '0 !important',
		},
		wrapperMargin: {
			paddingTop: '20px',
		},
		
	}));

export default function CenteredGrid() {
      const classes = useStyles();
    return <>
		<div id="header-wrapper">
		  <div id="header">
				<h1 className="icon-h1 forget-me">My Profile</h1>
				<a id="valido-logo" href="#"></a>
		   </div>
		 </div>
		<div id="main">
    	<Container maxWidth="lg">
			<Grid container spacing={2} style={{ paddingTop: '25px' }}>
				<Grid item xs={4} className="camera-section">
					<img className="profile-camera-icon" src={profileCameraIcon} alt="Camera" />
				</Grid>
				<Grid item xs={4} className="align-center profile-section">
					<img className="profile-avatar" src={profileAvatar} alt="Tony Roberts" />
				</Grid>
				<Grid item xs={4} className="delete-section">
					<img className="profile-trash-icon" src={profileTrashIcon} alt="Trash" />
				</Grid>
				<Grid item xs={6} style={{ paddingTop: '28px' }}>
					<TextField inputProps={{ className: "nvpInput" }} id="outlined-basic" defaultValue="Tony" label="First Name" InputLabelProps={{ className: "nvpLabel" }} variant="outlined" margin="dense" fullWidth />
				</Grid>
				<Grid item xs={6} style={{ paddingTop: '28px' }}>
					<TextField inputProps={{ className: "nvpInput" }} id="outlined-basic" defaultValue="Roberts" label="Last Name" InputLabelProps={{ className: "nvpLabel" }} variant="outlined" margin="dense" fullWidth />
				</Grid>
				<Grid item xs={12} style={{ paddingTop: '12px' }}>
					<TextField inputProps={{ className: "nvpInput" }} id="outlined-basic" defaultValue="tony.roberts@gmail.com" label="Email" InputLabelProps={{ className: "nvpLabel" }} variant="outlined" margin="dense" fullWidth />
				</Grid>
				<Grid item xs={12} style={{ paddingTop: '12px' }}>
					
				 </Grid>
				 <Grid item xs={6} style={{ paddingTop: '12px' }}>
					 <TextField inputProps={{ className: "nvpInput" }} id="outlined-basic" defaultValue="12/05/1980" label="Date of Birth" InputLabelProps={{ className: "nvpLabel" }} variant="outlined" margin="dense" fullWidth />
				 </Grid>
				 <Grid item xs={6} style={{ paddingTop: '12px' }}>
					 <TextField  inputProps={{ className: "nvpInput" }} id="select" value="Male" select variant="outlined" margin="dense" fullWidth>
						 <MenuItem value="Gender">Gender</MenuItem>
					   <MenuItem value="Male">Male</MenuItem>
					   <MenuItem value="Female">Female</MenuItem>
					   <MenuItem value="Other">Other</MenuItem>
					 </TextField>
				 </Grid>
			</Grid>
		</Container>
		<Container maxWidth="lg" className="buttonWrapperSticky">
			<Grid container spacing={2}>
				<Grid item xs={6}>
					<Button className={classes.nvpButton} variant="contained" color="secondary" size="large" disableElevation>Cancel</Button>
				</Grid>
				<Grid item xs={6}>
					<Button className={classes.nvpButton} variant="contained" color="secondary" size="large" disableElevation>Update</Button>
				</Grid>
			</Grid>
		</Container>
		</div>
		<div id="footer-wrapper">
			
			<div id="footer">
				<a className="footer-icon" id="footer-icon-home" href="#"></a>
				<a className="footer-icon" id="footer-icon-arrow-l" href="#"></a>
				<a className="footer-icon" id="footer-icon-arrow-r" href="#"></a>
				<a className="footer-icon" id="footer-icon-menu" href="#"></a>
			</div>
					
		</div>
    </>
}
