import makeStyles from '@mui/styles/makeStyles';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import imageMyer from '../assets/images/vendor-image-myer.jpg';
import logoMyer from '../assets/images/vendor-logo-myer.jpg';
import offerCloseIcon from '../assets/svg/modal-close-icon-grey.svg';


const useStyles = makeStyles((theme) => ({
		root: {
			flexGrow: 1,
		},
		nvpButton: {
			fontFamily: 'Nunito Sans',
			fontWeight: 'bold',
			textTransform: 'none',
			color: 'white',
			fontSize: '1.063rem',
		},
		gridNoMargin: {
			paddingTop: '0 !important',
			paddingBottom: '0 !important',
		},
		gridNoBottomMargin: {
			paddingBottom: '0 !important',
		},
		gridNoTopMargin: {
			paddingTop: '0 !important',
		},
		wrapperMargin: {
			paddingTop: '20px',
		},
		drawBG: {
			width: '100vw',
			height: '100vh',
			spacing: 0,
			justify: 'space-around',
			backgroundColor: '#4b4b4b',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
		},
		bottomDrawer: {
			background: 'white',
			padding: '25px 25px 60px 25px',
			borderTopLeftRadius: '5px',
			borderTopRightRadius: '5px',
			position: 'absolute',
			bottom: '0',
			width: 'calc(100% - 82px)!important',
		}
	}));

export default function CenteredGrid() {
		  const classes = useStyles();
		return <>
				<Container className={classes.drawBG}>
					<Box className={classes.bottomDrawer}>
						<Grid container spacing={2}>
							<Grid item xs={12} style={{ paddingTop: '10px' }}>
								<a className="offer-close-button" href="#"><img className="offer-close-icon" src={offerCloseIcon} alt="Close" /></a>
								<h2 className="icon-h2 end-parking-now no-bottom-padding">Do you wish to end parking now?</h2>
							</Grid>
							<Grid item xs={12}>
								<p className="fix-mobile-wrap">Selecting 'Yes' will redirect you to NuevoPark.</p>
							</Grid>
							<Grid item xs={6} className="mobile-button-override">
									<Button className={classes.nvpButton} variant="contained" color="secondary" size="large" disableElevation>No Thanks</Button>
								</Grid>
								<Grid item xs={6}  className="mobile-button-override">
									<Button className={classes.nvpButton} variant="contained" color="secondary" size="large" disableElevation>Yes, End Parking</Button>
								</Grid>
						</Grid>
					</Box>
				</Container>
		</>
	}