import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import logoMyer from '../assets/images/vendor-logo-myer.jpg';
import logoJBhifi from '../assets/images/vendor-logo-jbhifi.jpg';


const useStyles = makeStyles((theme) => ({
	  root: {
		flexGrow: 1,
	  },
		nvpButton: {
			fontFamily: 'Nunito Sans',
			fontWeight: 'bold',
			textTransform: 'none',
			color: 'white',
			fontSize: '1.063rem',
		},
		gridNoMargin: {
				padding: '0 !important',
			},
		gridNoBottomMargin: {
			  paddingBottom: '0 !important',
		},
		gridNoTopMargin: {
			paddingTop: '0 !important',
		},
		gridNoTopBottomMargin: {
				paddingTop: '0 !important',
				paddingBottom: '0 !important',
			},
		wrapperMargin: {
			paddingTop: '20px',
		},
	}));

export default function CenteredGrid() {
      const classes = useStyles();
    return <>
		<div id="header-wrapper">
		  <div id="header">
				<h1 className="no-icon parker-id">Parker ID QS00172 <br /> 
				<span className="subtitle">457 Franklin Street</span></h1>
				<a id="valido-icon" href="#"></a>
		   </div>
		 </div>
		<div id="main">
    	<Container maxWidth="lg">
			<Grid container spacing={2}>
				<Grid item xs={12} style={{ paddingTop: '5px' , paddingBottom: '0px' }}>
					<h2 className="icon-h2 free-parking-earned">Total FREE Parking <strong className="free-parking-earned">2hrs 30min</strong></h2>
				</Grid>
			</Grid>
			<Grid container spacing={2} className="network-link-wrapper">
				<Grid item xs={12} style={{ paddingBottom: '15px' }}>
					<p><a className="network-link" href="#">View Valido Carpark &amp; Merchant Network</a></p>
				</Grid>
			</Grid>
		</Container>
		<Container maxWidth="lg" className={classes.gridNoMargin}>
			<Grid container spacing={0} className="merchant-offer-block">
				<Grid item xs={12} className={classes.gridNoTopBottomMargin}>
					<img className="vendor-logo" src={logoJBhifi} alt="JB HiFi Logo" />
					<div className="merchant-title">
						<h4><a href="#">JB HiFi</a></h4>
						<h4 className="offer-time">30min</h4>
					</div>
					<div className="merchant-location"><p>Rundle Mall Plaza, Rundle Mall</p></div>
				</Grid>
			</Grid> 
			<Grid container spacing={0} className="merchant-offer-block">
				<Grid item xs={12} className={classes.gridNoTopBottomMargin}>
					<img className="vendor-logo" src={logoMyer} alt="Myer Logo" />
					<div className="merchant-title">
						<h4><a href="#">Myer</a></h4>
						<h4 className="offer-time">2hrs</h4>
					</div>
					<div className="merchant-location"><p>Myer Centre, Rundle Mall</p></div>
				</Grid>
			</Grid>
		</Container>
		<Container maxWidth="lg" className="buttonWrapperSticky">
			<Grid container spacing={2}>
				<Grid item xs={6} style={{ paddingTop: '0' }}>
					<Button className="entryButtonSmall record-purchase" variant="contained" color="secondary" size="large" disableElevation>Record Purchase</Button>
				</Grid>
				<Grid item xs={6} style={{ paddingTop: '0' }}>
					<Button className="entryButtonSmall claim-parking" variant="contained" color="secondary" size="large" disableElevation>Claim Free Parking</Button>
				</Grid>
			</Grid>
		</Container>
		</div>
		<div id="footer-wrapper">
			
			<div id="footer">
				<a className="footer-icon" id="footer-icon-home" href="#"></a>
				<a className="footer-icon" id="footer-icon-arrow-l" href="/eleven"></a>
				<a className="footer-icon" id="footer-icon-arrow-r" href="/thirteen"></a>
				<a className="footer-icon" id="footer-icon-menu" href="#"></a>
			</div>
					
		</div>
    </>
}
