import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import facebookLoginIcon from '../assets/svg/facebookLogin.svg';
import googleLoginIcon from '../assets/images/googleLogin.png';
import verifyMobileIcon from '../assets/svg/icon-verify-mobile.svg';


const useStyles = makeStyles((theme) => ({
	  root: {
		flexGrow: 1,
	  },
	  googleButton: {
		  backgroundColor: 'white',
		  backgroundImage: `url(${googleLoginIcon})`,
		  backgroundRepeat: 'no-repeat',
		  backgroundPosition: '25% center',
		  backgroundSize: '25px',
		  color: '#607C96!important',
		  fontFamily: 'Nunito Sans',
		  fontWeight: '600!important',
		  textTransform: 'none',
		  borderColor: '#E5E5E5',
		  borderWidth: '2px',
		  paddingTop: '9px',
		  paddingBottom: '9px',
		  fontSize: '1rem',
		  textIndent: '15%',
	  },
	  facebookButton: {
			backgroundColor: '#4267B2!important',
			backgroundImage: `url(${facebookLoginIcon})`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: '25% center',
			color: 'white',
			fontFamily: 'Nunito Sans',
			fontWeight: '400!important',
			textTransform: 'none',
			borderWidth: '2px',
			paddingTop: '10px',
			paddingBottom: '10px',
			fontSize: '1rem',
			textIndent: '25%',
		},
		verifyMobileButton: {
			backgroundImage: `url(${verifyMobileIcon})`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: '10% center',
			backgroundSize: '9%',
			backgroundColor: '#ffffff!important',
			borderColor: '#FD5A5F!important',
			color: '#FD5A5F',
			marginTop: '7px!important',
			padding: '8px!important',
			fontSize: '1rem!important',
			textIndent: '20px',
		},
		nvpButton: {
			fontFamily: 'Nunito Sans',
			fontWeight: 'bold',
			textTransform: 'none',
			color: 'white',
			fontSize: '1.063rem',
		},
		gridNoBottomMargin: {
			  paddingBottom: '0 !important',
		},
		gridNoTopMargin: {
			paddingTop: '0 !important',
		},
		wrapperMargin: {
			paddingTop: '20px',
		},
	}));

export default function CenteredGrid() {
      const classes = useStyles();
    return <>
		<div id="header-wrapper">
		  <div id="header">
				<h1 className="icon-h1 sign-up">Sign Up</h1>
				<a id="valido-logo" href="#"></a>
		   </div>
		 </div>
		<div id="main">
    	<Container maxWidth="lg">
			<Grid container spacing={2}>
				<Grid item xs={6}>
					<Button className={classes.facebookButton} variant="contained" color="primary" size="large" disableElevation>Facebook</Button>
				</Grid>
				<Grid item xs={6}>
					<Button className={classes.googleButton} variant="outlined" color="primary" size="large" disableElevation>Google</Button>
				</Grid>
				<Grid item xs={12}>
					<p className="bordered-text"><span className="bordered-text-mask">Or Email</span></p>
				</Grid>
				<Grid item xs={6} style={{ paddingTop: '0' }}>
					<TextField inputProps={{ className: "nvpInput" }} id="outlined-basic" label="First Name" defaultValue="Tony" InputLabelProps={{ className: "nvpLabel" }} variant="outlined" margin="dense" fullWidth />
				</Grid>
				<Grid item xs={6} style={{ paddingTop: '0' }}>
					<TextField inputProps={{ className: "nvpInput" }} id="outlined-basic" label="Last Name" defaultValue="Roberts" InputLabelProps={{ className: "nvpLabel" }} variant="outlined" margin="dense" fullWidth />
				</Grid>
				<Grid item xs={12} style={{ paddingTop: '12px' }}>
					<TextField inputProps={{ className: "nvpInput" }} id="outlined-basic" label="Email" defaultValue="tony.roberts@gmail.com" InputLabelProps={{ className: "nvpLabel" }} variant="outlined" margin="dense" fullWidth />
				</Grid>
				<Grid item xs={6} style={{ paddingTop: '12px' }}>
					<TextField inputProps={{ className: "nvpInput" }} id="outlined-basic" label="Password" defaultValue="•••••••••" InputLabelProps={{ className: "nvpLabel" }} variant="outlined" margin="dense" fullWidth />
				</Grid>
				<Grid item xs={6} style={{ paddingTop: '12px' }}>
					<TextField inputProps={{ className: "nvpInput" }} id="outlined-basic" label="Conifrm Password" defaultValue="•••••••••" InputLabelProps={{ className: "nvpLabel" }} variant="outlined" margin="dense" fullWidth />
				</Grid>
				<Grid item xs={6} style={{ paddingTop: '12px' }}>
					<TextField inputProps={{ className: "nvpInput" }} id="outlined-basic" label="Mobile Number" defaultValue="0413 392 723" InputLabelProps={{ className: "nvpLabel" }} variant="outlined" margin="dense" fullWidth />
				</Grid>
				<Grid item xs={6} style={{ paddingTop: '12px' }}>
					<Button className={classes.verifyMobileButton} variant="outlined" color="secondary" size="large" disableElevation>Verify Mobile</Button>
				</Grid>
				<Grid item xs={12}>
					<p style={{ textAlign: 'center' , paddingBottom: '0' }} className="fix-mobile-wrap">Enter the verification code sent to your mobile phone</p>
				</Grid>
				<Grid item xs={10} style={{ paddingTop: '0px' }}>
					<TextField inputProps={{ className: "nvpInput" }} id="outlined-basic" label="Verification Code" defaultValue="0123456" InputLabelProps={{ className: "nvpLabel" }} variant="outlined" margin="dense" fullWidth />
				</Grid>
				<Grid item xs={2} style={{ paddingTop: '0px' }}>
					<p className="forgot-password-right" style={{ paddingTop: '20px' }}><a href="#">Resend</a></p>
				</Grid>
			</Grid>
		</Container>
		<Container maxWidth="lg" className="buttonWrapperSticky" style={{ paddingTop: '0' }}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<p className="centered-text-smaller">Already have an account? <a href="#"><strong>Sign In</strong></a></p>
					<Button className={classes.nvpButton} variant="contained" color="secondary" size="large" disableElevation>Sign Up</Button>
				</Grid>
			</Grid>
		</Container>
		</div>
		<div id="footer-wrapper">
			
			<div id="footer">
				<a className="footer-icon" id="footer-icon-home" href="#"></a>
				<a className="footer-icon" id="footer-icon-arrow-l" href="/sixteen"></a>
				<a className="footer-icon" id="footer-icon-arrow-r" href="/eighteen"></a>
				<a className="footer-icon" id="footer-icon-user" href="#"></a>
			</div>
					
		</div>
    </>
}
