import makeStyles from '@mui/styles/makeStyles';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import {styled} from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, {SwitchProps} from '@mui/material/Switch';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import offerCloseIcon from '../assets/svg/modal-close-icon-grey.svg';


const useStyles = makeStyles((theme) => ({
	  root: {
		flexGrow: 1,
	  },
	  gridNoMargin: {
		  paddingTop: '0 !important',
		  paddingBottom: '0 !important',
	  },
		gridNoBottomMargin: {
			  paddingBottom: '0 !important',
		},
		gridNoTopMargin: {
			paddingTop: '0 !important',
		},
		wrapperMargin: {
			paddingTop: '20px',
		},
	}));
	
const IOSSwitch = styled((props: SwitchProps) => (
	  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
	))(({ theme }) => ({
	  width: 64,
	  height: 32,
	  padding: 0,
	  marginRight: '10px',
	  '& .MuiSwitch-switchBase': {
		padding: 0,
		margin: 4,
		transitionDuration: '300ms',
		'&.Mui-checked': {
		  transform: 'translateX(32px)',
		  color: '#fff',
		  '& + .MuiSwitch-track': {
			backgroundColor: '#1c92ad',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: '12px center',
			backgroundSize: '23%',
			opacity: 1,
			border: 0,
		  },
		  '&.Mui-disabled + .MuiSwitch-track': {
			opacity: 0.5,
		  },
		},
		'&.Mui-focusVisible .MuiSwitch-thumb': {
		  color: '#33cf4d',
		  border: '6px solid #fff',
		},
		'&.Mui-disabled .MuiSwitch-thumb': {
		  color: 'black',
		},
		'&.Mui-disabled + .MuiSwitch-track': {
		  opacity: 0.5,
		},
	  },
	  '& .MuiSwitch-thumb': {
		boxSizing: 'border-box',
		width: 24,
		height: 24,
	  },
	  '& .MuiSwitch-track': {
		borderRadius: 32 / 2,
		backgroundColor: '#CBD3DC',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: '75% center',
		backgroundSize: '23%',
		opacity: 1,
		transition: theme.transitions.create(['background-color'], {
		  duration: 500,
		}),
	  },
	}));

export default function CenteredGrid() {
      const classes = useStyles();
    return <>
		<div id="header-wrapper" className="no-page-title">
			  <div id="header">
				
			   </div>
			   <div className="fixed-title-wrapper"></div>
			 </div>
			<div id="main" className="no-header fixed-overlay">
			<Container maxWidth="lg" className="parking-offers-wrapper">
				<Box bgcolor="white" display="flex" style= {{ width: '100%' }}>
					<Grid container spacing={2}>
						<Grid item xs={12} style={{ paddingTop: '5px' }}>
							<a className="offer-close-button" href="#"><img className="offer-close-icon" src={offerCloseIcon} alt="Close" /></a>
							<h2 className="icon-h2 select-location">Select Location</h2>
						</Grid>
						<Grid item xs={8} className={classes.gridNoMargin}>
							<p className="vendor-description" style={{ lineHeight: '32px' , paddingBottom: '0' }}>Use my mobile device to find my location.</p>
						</Grid>
						<Grid item xs={4} className={classes.gridNoMargin}>
							<FormGroup>
							  <FormControlLabel className="custom-switch-disabled"
								control={<IOSSwitch />}
								label=""
								labelPlacement="start"
							  />
							</FormGroup>
						</Grid>
						<Grid item xs={12}>
							<Select className="validoSelect"
								IconComponent={ExpandMoreIcon}
								  MenuProps={{
									anchorOrigin: {
									  vertical: "bottom",
									  horizontal: "left"
									},
									transformOrigin: {
									  vertical: "top",
									  horizontal: "left"
									},
							
								  }}
							  value="10" variant="outlined" margin="dense" fullWidth>
								<MenuItem value="10" className="selectBold">State</MenuItem>
								<MenuItem value="20">SA</MenuItem>
								<MenuItem value="30">VIC</MenuItem>
								<MenuItem value="40">NSW</MenuItem>
								<MenuItem value="50">QLD</MenuItem>
								<MenuItem value="60">WA</MenuItem>
								<MenuItem value="70">TAS</MenuItem>
								<MenuItem value="80">NT</MenuItem>
							 </Select>
						</Grid>
						<Grid item xs={12}>
							<Select className="validoSelect"
								IconComponent={ExpandMoreIcon}
								  MenuProps={{
									anchorOrigin: {
									  vertical: "bottom",
									  horizontal: "left"
									},
									transformOrigin: {
									  vertical: "top",
									  horizontal: "left"
									},
							
								  }}
							  value="10" variant="outlined" margin="dense" fullWidth>
								<MenuItem value="10" className="selectBold">City</MenuItem>
								<MenuItem value="20">Adelaide</MenuItem>
								<MenuItem value="30">Melbourne</MenuItem>
								<MenuItem value="40">Sydney</MenuItem>
								<MenuItem value="50">Brisbane</MenuItem>
								<MenuItem value="60">Perth</MenuItem>
								<MenuItem value="70">Hobart</MenuItem>
								<MenuItem value="80">Darwin</MenuItem>
							 </Select>
						</Grid>
					
					<div className="align-bottom" style={{ height: '80%' }}>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<Button className="entryButtonMedium view-carparks" variant="contained" color="secondary" size="large" disableElevation>View Carparks</Button>
							</Grid>
							<Grid item xs={6}>
								<Button className="entryButtonMedium view-merchants" variant="contained" color="secondary" size="large" disableElevation>View Merchants</Button>
							</Grid>
						</Grid>
					</div>
					</Grid>
				</Box>
			</Container>
			</div>
    </>
}
