import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import voucherBG from '../assets/images/transaction-id-bg-only.png';
import logoJBhifi from '../assets/images/vendor-logo-jbhifi.jpg';

const useStyles = makeStyles((theme) => ({
	  root: {
		flexGrow: 1,
	  },
		nvpButton: {
			fontFamily: 'Nunito Sans',
			fontWeight: 'bold',
			textTransform: 'none',
			color: 'white',
			fontSize: '1.063rem',
		},
		gridNoBottomMargin: {
			  paddingBottom: '0 !important',
		},
		gridNoTopMargin: {
			paddingTop: '0 !important',
		},
		gridNoTopBottomMargin: {
			paddingTop: '0 !important',
			paddingBottom: '0 !important',
		},
		wrapperMargin: {
			paddingTop: '20px',
		},
		voucherBoxYellowBG: {
			backgroundImage: `url(${voucherBG})`,
			backgroundColor: '#FACA2E',
			backgroundSize: '100%',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center center',
			textAlign: 'center',
			minHeight: '256px',
			marginTop: '45px!important',
			marginBottom: '10px',
			marginLeft: '16px!important',
			paddingRight: '16px!important',
			borderRadius: '10px',
		},
	}));

export default function CenteredGrid() {
      const classes = useStyles();
    return <>
		<div id="header-wrapper">
		  <div id="header">
				<h1 className="no-icon parker-id">Parker ID QS00172 <br /> 
				<span className="subtitle">Metro City Council</span></h1>
				<a id="valido-icon" href="#"></a>
		   </div>
		 </div>
		<div id="main">
    	<Container maxWidth="lg">
			<Grid container spacing={2}>
				<Grid item xs={12} style={{ paddingTop: '10px' }}>
					<h2 className="icon-h2 transaction-id">Transaction ID</h2>
				</Grid>
			</Grid>
			<Grid container spacing={2} className="merchant-offer-block">
				<Grid item xs={12} className={classes.gridNoTopBottomMargin}>
					<img className="vendor-logo" src={logoJBhifi} alt="JB HiFi Logo" />
					<div className="merchant-title">
						<h4><a href="#">JB HiFi</a></h4>
					</div>
					<div className="merchant-location"><p>Rundle Mall Plaza, Rundle Mall</p></div>
				</Grid>
			</Grid>
			<Grid container spacing={2}>
				<Grid item xs={12} className={classes.voucherBoxYellowBG}>
					<p className="transaction-small-text">Transaction ID</p>
					<h1 className="transaction-id">Y09</h1>
				</Grid>
				<Grid item xs={12}>
					<p className="transaction-message">The cashier needs the <a href="#">Transaction ID</a> to load your free parking on to your Valido account.</p>			
				</Grid>
			</Grid>
		</Container>
		</div>
		<div id="footer-wrapper">
			
			<div id="footer">
				<a className="footer-icon" id="footer-icon-home" href="#"></a>
				<a className="footer-icon" id="footer-icon-arrow-l" href="#"></a>
				<a className="footer-icon" id="footer-icon-arrow-r" href="#"></a>
				<a className="footer-icon" id="footer-icon-menu" href="#"></a>
			</div>
					
		</div>
    </>
}
