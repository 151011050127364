import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import editIconSml from '../assets/images/edit-icon-sml.png';
import deleteIconSml from '../assets/images/delete-icon-sml.png';
import cameraIcon from '../assets/svg/button-icon-camera-white.svg';
import yourCar from '../assets/images/car-in-park.jpg';

const useStyles = makeStyles((theme) => ({
	  root: {
		flexGrow: 1,
	  },
		nvpButton: {
			fontFamily: 'Nunito Sans',
			fontWeight: 'bold',
			textTransform: 'none',
			color: 'white',
			fontSize: '1.063rem',
		},
		nvpScanButton: {
			backgroundImage: `url(${cameraIcon})`,
			backgroundSize: '24px',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: '32% center',
			fontFamily: 'Nunito Sans',
			fontWeight: 'bold',
			textTransform: 'none',
			color: 'white',
			fontSize: '1.063rem',
		},
		gridNoMargin: {
			padding: '0 !important',
		},
		gridNoBottomMargin: {
			paddingBottom: '0 !important',
		},
		gridNoTopMargin: {
			paddingTop: '0 !important',
		},
		gridNoTopBottomMargin: {
			paddingTop: '0 !important',
			paddingBottom: '0 !important',
		},
		wrapperMargin: {
			paddingTop: '20px',
		},
	}));

export default function CenteredGrid() {
      const classes = useStyles();
    return <>
		<div id="header-wrapper">
		  <div id="header">
				<h1 className="icon-h1 entry-method">Your Park</h1>
				<a id="valido-logo" href="#"></a>
		   </div>
		 </div>
		<div id="main">
    	<Container maxWidth="lg">
			<Grid container spacing={2}>
				<Grid item xs={12} style={{ paddingTop: '10px' }}>
					<h2 className="parking-selection no-padding"><strong>457 Franklin</strong> (ID.47235) <a href="#" className="parking-location-pin"></a></h2>
					<h4 className="date-selected">03/11/21 09:41</h4>
				</Grid>
				<Grid item xs={12} style={{ paddingTop: '20px' }} className="car-photo-box">
					<img className="your-car" src={yourCar} alt="Car in park" />
				</Grid>
			</Grid>
		</Container>
		<Container maxWidth="lg" className="buttonWrapperSticky">
			<Grid container spacing={2}>
				<Grid item xs={6}>
					<Button className={classes.nvpButton} variant="contained" color="secondary" size="large" disableElevation>Back</Button>
				</Grid>
				<Grid item xs={6}>
					<Button className={classes.nvpButton} variant="contained" color="secondary" size="large" disableElevation>Confirm</Button>
				</Grid>
			</Grid>
		</Container>
		</div>
		<div id="footer-wrapper">
			
			<div id="footer">
				<a className="footer-icon" id="footer-icon-home" href="#"></a>
				<a className="footer-icon" id="footer-icon-arrow-l" href="#"></a>
				<a className="footer-icon" id="footer-icon-arrow-r" href="#"></a>
				<a className="footer-icon" id="footer-icon-menu" href="#"></a>
			</div>
					
		</div>
    </>
}
