import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import deleteAccIcon from '../assets/images/delete-account-icon-lrg.png';


const useStyles = makeStyles((theme) => ({
	  root: {
		flexGrow: 1,
	  },
		nvpButton: {
			fontFamily: 'Nunito Sans',
			fontWeight: 'bold',
			textTransform: 'none',
			color: 'white',
			fontSize: '1.063rem',
		},
		gridNoBottomMargin: {
			  paddingBottom: '0 !important',
		},
		gridNoTopMargin: {
			paddingTop: '0 !important',
		},
		wrapperMargin: {
			paddingTop: '20px',
		},
	}));

export default function CenteredGrid() {
      const classes = useStyles();
    return <>
		<div id="header-wrapper">
		  <div id="header">
				<h1 className="icon-h1 sign-up">Forget Me</h1>
				<a id="nuevopark-logo" href="#"></a>
		   </div>
		 </div>
		<div id="main">
    	<Container maxWidth="lg">
			<Grid container spacing={2} className="delete-account-text-block">
				<Grid item xs={12}>
					<img className="delete-acc-icon" src={deleteAccIcon} alt="Delete Account" />
					<h4>Delete Account</h4>
					<p className="smaller-text-size">Deleting your account will remove all of your information from the database.</p>
					<p className="smaller-text-size">This cannot be undone</p>
				</Grid>
				<Grid item xs={12}>
					<TextField inputProps={{ className: "nvpInput" }} id="outlined-basic" label="Password" defaultValue="* * * * * * * * *" InputLabelProps={{ className: "nvpLabel" }} variant="outlined" margin="dense" fullWidth />
				</Grid>
			</Grid>
		</Container>
		<Container maxWidth="lg" className="buttonWrapperSticky">
			<Grid container spacing={2}>
				<Grid item xs={6}>
					<Button className={classes.nvpButton} variant="contained" color="secondary" size="large" disableElevation>Cancel</Button>
				</Grid>
				<Grid item xs={6}>
					<Button className={classes.nvpButton} variant="contained" color="secondary" size="large" disableElevation>Delete Account</Button>
				</Grid>
			</Grid>
		</Container>
		</div>
		<div id="footer-wrapper">
			
			<div id="footer">
				<a className="footer-icon" id="footer-icon-home" href="#"></a>
				<a className="footer-icon" id="footer-icon-arrow-l" href="#"></a>
				<a className="footer-icon" id="footer-icon-arrow-r" href="#"></a>
				<a className="footer-icon" id="footer-icon-umenu" href="#"></a>
			</div>
					
		</div>
    </>
}
