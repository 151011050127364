import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import editIconSml from '../assets/images/edit-icon-sml.png';
import deleteIconSml from '../assets/images/delete-icon-sml.png';

const useStyles = makeStyles((theme) => ({
	  root: {
		flexGrow: 1,
	  },
		nvpButton: {
			fontFamily: 'Nunito Sans',
			fontWeight: 'bold',
			textTransform: 'none',
			color: 'white',
			fontSize: '1.063rem',
		},
		gridNoMargin: {
			padding: '0 !important',
		},
		gridNoBottomMargin: {
			paddingBottom: '0 !important',
		},
		gridNoTopMargin: {
			paddingTop: '0 !important',
		},
		gridNoTopBottomMargin: {
			paddingTop: '0 !important',
			paddingBottom: '0 !important',
		},
		wrapperMargin: {
			paddingTop: '20px',
		},
	}));

export default function CenteredGrid() {
      const classes = useStyles();
    return <>
		<div id="header-wrapper">
		  <div id="header">
				<h1 className="icon-h1 entry-method">Entry Method</h1>
				<a id="valido-logo" href="#"></a>
		   </div>
		 </div>
		<div id="main">
    	<Container maxWidth="lg">
			<Grid container spacing={2}>
				<Grid item xs={12} style={{ paddingTop: '10px' }}>
					<h2 className="icon-h2 enter-vehicle-details no-padding">Enter your Vehicle Details</h2>
				</Grid>
				<Grid item xs={12} style={{ paddingTop: '10px' }}>
					<TextField inputProps={{ className: "nvpInput" }} id="outlined-basic" label="Lic. Plate" defaultValue="S45278A" InputLabelProps={{ className: "nvpLabel" }} variant="outlined" margin="dense" fullWidth />
				</Grid>
				<Grid item xs={12} style={{ paddingTop: '15px' }}>
					<h2 className="icon-h2 my-vehicles">My Vehicles</h2>
				</Grid>
			</Grid>
		</Container>
		<Container maxWidth="lg" className={classes.gridNoMargin}>
			<Grid container spacing={0} className="my-entries-block">
				<Grid item xs={12} className={classes.gridNoTopBottomMargin}>
					<h4 className="vehicle-name">Skoda S578BMT</h4>
				</Grid>
			</Grid>
		</Container>
		<Container maxWidth="lg" className="buttonWrapperSticky">
			<Grid container spacing={2}>
				<Grid item xs={6}>
					<Button className={classes.nvpButton} variant="contained" color="secondary" size="large" disableElevation>Back</Button>
				</Grid>
				<Grid item xs={6}>
					<Button className={classes.nvpButton} variant="contained" color="secondary" size="large" disableElevation>Next</Button>
				</Grid>
			</Grid>
		</Container>
		</div>
		<div id="footer-wrapper">
			
			<div id="footer">
				<a className="footer-icon" id="footer-icon-home" href="#"></a>
				<a className="footer-icon" id="footer-icon-arrow-l" href="#"></a>
				<a className="footer-icon" id="footer-icon-arrow-r" href="#"></a>
				<a className="footer-icon" id="footer-icon-menu" href="#"></a>
			</div>
					
		</div>
    </>
}
