import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import voucherBG from '../assets/images/transaction-id-bg-only.png';
import logoEBGames from '../assets/images/vendor-logo-ebgames.jpg';

const useStyles = makeStyles((theme) => ({
	  root: {
		flexGrow: 1,
	  },
		nvpButton: {
			fontFamily: 'Nunito Sans',
			fontWeight: 'bold',
			textTransform: 'none',
			color: 'white',
			fontSize: '1.063rem',
		},
		gridNoBottomMargin: {
			  paddingBottom: '0 !important',
		},
		gridNoTopMargin: {
			paddingTop: '0 !important',
		},
		gridNoTopBottomMargin: {
			paddingTop: '0 !important',
			paddingBottom: '0 !important',
		},
		wrapperMargin: {
			paddingTop: '20px',
		},
		voucherBoxBlueBG: {
			backgroundImage: `url(${voucherBG})`,
			backgroundColor: '#5894f7',
			backgroundSize: '100%',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center center',
			textAlign: 'center',
			minHeight: '256px',
			marginTop: '30px',
			marginBottom: '10px',
			borderRadius: '10px',
		},
	}));

export default function CenteredGrid() {
      const classes = useStyles();
    return <>
		<div id="header-wrapper">
		  <div id="header">
				<h1 className="icon-h1 my-activity">My Activity</h1>
				<a id="list-activity" href="#"></a>
				<a id="search-activity" href="#"></a>
		   </div>
		 </div>
		<div id="main">
    	<Container maxWidth="lg" disableGutters>
			<Grid container spacing={2} className="date-range-wrapper">
				<Grid item xs={12} style={{ paddingTop: '10px' }}>
					<h3 className="icon-h3 date-range">03/11/21</h3>
				</Grid>
			</Grid>
			<Grid container spacing={2} className="activity-entry-block">
				<Grid item xs={12} className={classes.gridNoTopBottomMargin}>
					<div className="activity-title">
						<a href="#" className="view-location"></a>
						<h4 className="parking-location"><a href="#">Metro City Council <span className="location-id">(ID.47235)</span></a></h4>
					</div>
					<div className="activity-details">
						<p className="activity-date-range">03/11/21 09:41 - 03/11/21 16:56</p>
						<p className="activity-chosen-car">Volkswagen S45278A <span className="activity-parking-started">Started</span></p>
					</div>
				</Grid>
			</Grid>
			<div className="activity-actions">
				<a href="#" className="activity-end">End</a>
				<a href="#" className="activity-extend">Extend</a>
				<a href="#" className="activity-view-ticket">View Ticket</a>
			</div>
		</Container>
		</div>
		<div id="footer-wrapper">
			
			<div id="footer">
				<a className="footer-icon" id="footer-icon-home" href="#"></a>
				<a className="footer-icon" id="footer-icon-arrow-l" href="#"></a>
				<a className="footer-branding" id="footer-icon-logo" href="#"></a>
				<a className="footer-icon" id="footer-icon-arrow-r" href="#"></a>
				<a className="footer-icon" id="footer-icon-menu" href="#"></a>
			</div>
					
		</div>
    </>
}
