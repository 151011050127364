import makeStyles from '@mui/styles/makeStyles';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import slideImage from '../assets/images/slider-tile-01.jpg';
import validoApiIcon from '../assets/svg/button-icon-home-api.svg';
import validoQrcIcon from '../assets/svg/button-icon-home-qrc.svg';
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
    	flexGrow: 1,
    },
	vqrButton: {
		fontFamily: 'Nunito Sans',
		fontWeight: 'bold',
		textTransform: 'none',
		fontSize: '1.063rem',
		borderRadius: '50px',
		borderWidth: '2px',
		letterSpacing: '0',
	},
	validoApiButton: {
		backgroundImage: `url(${validoApiIcon})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: '30px center',
		backgroundSize: '90px',
		fontSize: '2rem!important',
		fontWeight: '400!important',
		paddingTop: '60px!important',
		paddingBottom: '60px!important',
		textIndent: '90px',
	},
	validoQrcButton: {
		backgroundImage: `url(${validoQrcIcon})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: '30px center',
		backgroundSize: '80px',
		fontSize: '2rem!important',
		fontWeight: '400!important',
		paddingTop: '60px!important',
		paddingBottom: '60px!important',
		textIndent: '90px',
	},
	gridNoTopMargin: {
	    paddingBottom: '0 !important',
    },
    gridNoMargin: {
	    paddingTop: '0 !important',
	    paddingBottom: '0 !important',
    },
	slideWrapper: {
		backgroundColor: '#eeeeee',
		paddingLeft: '5% !important',
		paddingRight: '5% !important',
		paddingTop: '10px',
		marginTop: '0px',
		textAlign: 'center',
	}
}));

export default function CenteredGrid() {
      const classes = useStyles();
    return <>
		<div id="header-wrapper" className="landing-page-header">
			<div id="header">
				<a id="valido-logo" className="landing-page" href="#"></a>
				<h2 className="entryScreen">Shop, dine, play and <br /> <strong>save on parking</strong></h2>
				<div className="header-button-wrapper">
					<p><a className="header-button">How it works</a><br />
					<a className="header-button">Valido Carpark &amp; Merchant Network</a></p>
				</div>
		   </div>
		</div>
	    	<Container maxWidth="lg">
		        <Grid container spacing={2}>
					<Grid item xs={12}>
						<Link to="/two"><Button className={classes.validoApiButton} variant="contained" color="secondary" size="large" disableElevation>Valido API</Button></Link>
		            </Grid>
					<Grid item xs={12}>
						<Link to="/two"><Button className={classes.validoQrcButton} variant="contained" color="secondary" size="large" disableElevation>Valido QRC</Button></Link>
					</Grid>
		        </Grid>
	        </Container>
	</>
}

