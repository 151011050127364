import makeStyles from '@mui/styles/makeStyles';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import slideImage from '../assets/images/slider-tile-04.jpg';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	nvpButton: {
		fontFamily: 'Nunito Sans',
		fontWeight: 'bold',
		textTransform: 'none',
		color: 'white',
		fontSize: '1.063rem',
	},
	vqrButton: {
		fontFamily: 'Nunito Sans',
		fontWeight: 'bold',
		textTransform: 'none',
		fontSize: '1.063rem',
		borderRadius: '50px',
		borderWidth: '2px',
		letterSpacing: '0',
	},
	gridNoTopMargin: {
		paddingBottom: '0 !important',
	},
	gridNoMargin: {
		paddingTop: '0 !important',
		paddingBottom: '0 !important',
	},
	slideWrapper: {
		backgroundColor: '#eeeeee',
		paddingLeft: '5% !important',
		paddingRight: '5% !important',
		paddingTop: '10px',
		marginTop: '0px',
		textAlign: 'center',
	}
}));

export default function CenteredGrid() {
		  const classes = useStyles();
		return <>
			<div id="header-wrapper" className="landing-page-header">
				<div id="header">
					<a id="valido-logo" className="landing-page" href="#"></a>
					<h2 className="entryScreen">Shop, dine, play and <br /> <strong>save on parking</strong></h2>
					<div className="header-button-wrapper">
						<p><a className="header-button">How it works</a><br />
						<a className="header-button">Valido Carpark &amp; Merchant Network</a></p>
					</div>
			   </div>
			</div>
				<Container maxWidth="lg" style={{ paddingLeft: '0px' , paddingRight: '0px' }}>
					<Grid container spacing={2}>
						<Grid item xs={12} className={classes.slideWrapper}>
							<img className="slider-image" src={slideImage} alt="Slide Image" />
							<p className="slider-text">Boost your rewards when dining...</p>
						</Grid>
					</Grid>
				</Container>
				<Container maxWidth="lg" className="buttonWrapperSticky">
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Button className={classes.nvpButton} variant="contained" color="secondary" size="large" disableElevation>Launch Valido to earn FREE parking</Button>
						</Grid>
					</Grid>
				</Container>
			<div id="footer-wrapper">
				<div id="footer">
					<a className="footer-icon" id="footer-icon-home" href="#"></a>
					<a className="footer-icon" id="footer-icon-arrow-l" href="/three"></a>

					<a className="footer-icon" id="footer-icon-arrow-r" href="/five"></a>
					<a className="footer-icon" id="footer-icon-user" href="#"></a>
				</div>
			</div>
		</>
	}

