import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import facebookLoginIcon from '../assets/svg/facebookLogin.svg';
import googleLoginIcon from '../assets/images/googleLogin.png';


const useStyles = makeStyles((theme) => ({
	  root: {
		flexGrow: 1,
	  },
	  googleButton: {
		backgroundColor: 'white',
		backgroundImage: `url(${googleLoginIcon})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: '25% center',
		backgroundSize: '25px',
		color: '#607C96!important',
		fontFamily: 'Nunito Sans',
		fontWeight: '600!important',
		textTransform: 'none',
		borderColor: '#E5E5E5',
		borderWidth: '2px',
		paddingTop: '9px',
		paddingBottom: '9px',
		fontSize: '1rem',
		textIndent: '15%',
	},
	facebookButton: {
		  backgroundColor: '#4267B2!important',
		  backgroundImage: `url(${facebookLoginIcon})`,
		  backgroundRepeat: 'no-repeat',
		  backgroundPosition: '25% center',
		  color: 'white',
		  fontFamily: 'Nunito Sans',
		  fontWeight: '400!important',
		  textTransform: 'none',
		  borderWidth: '2px',
		  paddingTop: '10px',
		  paddingBottom: '10px',
		  fontSize: '1rem',
		  textIndent: '25%',
	  },
		nvpButton: {
			fontFamily: 'Nunito Sans',
			fontWeight: 'bold',
			textTransform: 'none',
			color: 'white',
			fontSize: '1.063rem',
		},
		gridNoBottomMargin: {
			  paddingBottom: '0 !important',
		},
		gridNoTopMargin: {
			paddingTop: '0 !important',
		},
		wrapperMargin: {
			paddingTop: '20px',
		},
	}));

export default function CenteredGrid() {
      const classes = useStyles();
    return <>
		<div id="header-wrapper">
		  <div id="header">
				<h1 className="icon-h1 sign-in">Sign In</h1>
				<a id="valido-logo" href="#"></a>
		   </div>
		 </div>
		<div id="main">
    	<Container maxWidth="lg">
			<Grid container spacing={2}>
				<Grid item xs={6}>
					<Button className={classes.facebookButton} variant="contained" color="primary" size="large">Facebook</Button>
				</Grid>
				<Grid item xs={6}>
					<Button className={classes.googleButton} variant="outlined" color="primary" size="large">Google</Button>
				</Grid>
				<Grid item xs={12}>
					<p className="bordered-text"><span className="bordered-text-mask">Or Email</span></p>
				</Grid>
				<Grid item xs={12} style={{ paddingTop: '0' }}>
					<TextField inputProps={{ className: "nvpInput" }} id="outlined-basic" label="Email" defaultValue="tony.roberts@gmail.com" InputLabelProps={{ className: "nvpLabel" }} variant="outlined" margin="dense" fullWidth />
				</Grid>
				<Grid item xs={12} style={{ paddingTop: '10px' }}>
					<TextField inputProps={{ className: "nvpInput" }} id="outlined-basic" label="Password" defaultValue="**********" InputLabelProps={{ className: "nvpLabel" }} variant="outlined" margin="dense" fullWidth />
				</Grid>
				<Grid item xs={12}>
					<p className="forgot-password-right"><a href="#">Forgot Password?</a></p>
				</Grid>
			</Grid>
		</Container>
		<Container maxWidth="lg" className="buttonWrapperSticky">
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<p className="centered-text-smaller">First time here? <a href="#"><strong>Sign Up</strong></a></p>
					<Button className={classes.nvpButton} variant="contained" color="secondary" size="large" disableElevation>Sign In</Button>
				</Grid>
			</Grid>
		</Container>
		</div>
		<div id="footer-wrapper">
			
			<div id="footer">
				<a className="footer-icon" id="footer-icon-home" href="#"></a>
				<a className="footer-icon" id="footer-icon-arrow-l" href="#"></a>
				<a className="footer-icon" id="footer-icon-arrow-r" href="#"></a>
				<a className="footer-icon" id="footer-icon-user" href="#"></a>
			</div>
					
		</div>
    </>
}
