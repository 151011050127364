import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import verifyMobileIcon from '../assets/svg/icon-verify-mobile-white.svg';

const useStyles = makeStyles((theme) => ({
	  root: {
		flexGrow: 1,
	  },
		nvpButton: {
			fontFamily: 'Nunito Sans',
			fontWeight: 'bold',
			textTransform: 'none',
			color: 'white',
			fontSize: '1.063rem',
			marginTop: '8px!important',
		},
		verifyMobileButton: {
			backgroundImage: `url(${verifyMobileIcon})`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: '10% center',
			backgroundSize: '9%',
			backgroundColor: '#FD5A5F!important',
			borderColor: '#FD5A5F!important',
			color: '#FD5A5F',
			marginTop: '9px!important',
			padding: '8px!important',
			fontSize: '1rem!important',
			textIndent: '20px',
		},
		gridNoMargin: {
			padding: '0 !important',
		},
		gridNoBottomMargin: {
			paddingBottom: '0 !important',
		},
		gridNoTopMargin: {
			paddingTop: '0 !important',
		},
		gridNoTopBottomMargin: {
			paddingTop: '0 !important',
			paddingBottom: '0 !important',
		},
		wrapperMargin: {
			paddingTop: '20px',
		},
	}));

export default function CenteredGrid() {
      const classes = useStyles();
    return <>
		<div id="header-wrapper">
		  <div id="header">
				<h1 className="icon-h1 forget-me">My Profile</h1>
				<a id="valido-logo" href="#"></a>
		   </div>
		 </div>
		<div id="main">
    	<Container maxWidth="lg">
			<Grid container spacing={2}>
				<Grid item xs={12} style={{ paddingTop: '10px' }}>
					<h2 className="icon-h2 security-update">Security - Password</h2>
					<p className="fix-mobile-wrap">To change your profile enter your password below.</p>
				</Grid>
				<Grid item xs={6} style={{ paddingTop: '0' }}>
					<TextField inputProps={{ className: "nvpInput" }} id="outlined-basic" label="Password" InputLabelProps={{ className: "nvpLabel" }} variant="outlined" margin="dense" fullWidth />
				</Grid>
				<Grid item xs={6} style={{ paddingTop: '0' }}>
					<Button className={classes.nvpButton} variant="contained" color="secondary" size="large" disableElevation>Confirm</Button>
				</Grid>
				<Grid item xs={12} style={{ paddingTop: '25px' }}>
					<h2 className="icon-h2 security-update disabled no-padding">Mobile Verification</h2>
				</Grid>
				<Grid item xs={6}>
					<TextField inputProps={{ className: "nvpInput" }} id="outlined-basic" label="041* *** 723" InputLabelProps={{ className: "nvpLabel" }} variant="outlined" margin="dense" fullWidth />
				</Grid>
				<Grid item xs={6}>
					<Button className={classes.verifyMobileButton} variant="contained" color="secondary" size="large" disableElevation>Verify Mobile</Button>
				</Grid>
				<Grid item xs={12}>
					<p style={{ paddingTop: '10px' , paddingBottom: '0' }} className="fix-mobile-wrap">Enter the verification code sent to your mobile phone.</p>
				</Grid>
				<Grid item xs={12}>
					<TextField inputProps={{ className: "nvpInput" }} id="outlined-basic" label="Verification Code" InputLabelProps={{ className: "nvpLabel" }} variant="outlined" margin="dense" fullWidth />
					<p className="resend-link" style={{ paddingTop: '10px' }}><a href="#">Resend</a></p>
				</Grid>
			</Grid>
		</Container>
		<Container maxWidth="lg" className="buttonWrapperSticky">
			<Grid container spacing={2}>
				<Grid item xs={6}>
					<Button className={classes.nvpButton} variant="contained" color="secondary" size="large" disableElevation>Cancel</Button>
				</Grid>
				<Grid item xs={6}>
					<Button className={classes.nvpButton} variant="contained" color="secondary" size="large" disabled disableElevation>Update</Button>
				</Grid>
			</Grid>
		</Container>
		</div>
		<div id="footer-wrapper">
			
			<div id="footer">
				<a className="footer-icon" id="footer-icon-home" href="#"></a>
				<a className="footer-icon" id="footer-icon-arrow-l" href="#"></a>
				<a className="footer-icon" id="footer-icon-arrow-r" href="#"></a>
				<a className="footer-icon" id="footer-icon-menu" href="#"></a>
			</div>
					
		</div>
    </>
}
