import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';


const useStyles = makeStyles((theme) => ({
	  root: {
		flexGrow: 1,
	  },
		nvpButton: {
			fontFamily: 'Nunito Sans',
			fontWeight: 'bold',
			textTransform: 'none',
			color: 'white',
			fontSize: '1.063rem',
		},
		gridNoBottomMargin: {
			  paddingBottom: '0 !important',
		},
		gridNoTopMargin: {
			paddingTop: '0 !important',
		},
		wrapperMargin: {
			paddingTop: '20px',
		},
	}));

export default function CenteredGrid() {
      const classes = useStyles();
    return <>
		<div id="header-wrapper" className="landing-page-header">
			<div id="header">
				<a id="valido-logo" className="landing-page" href="#"></a>
				<h2 className="entryScreen">Shop, dine, play and <br /> <strong>save on parking</strong></h2>
		   </div>
		</div>
		<div id="main" className="congrats-header">
    	<Container maxWidth="lg">
			<Grid container spacing={2} className="congrats-text-block">
				<Grid item xs={12}>
					<h3>Congratulations!</h3>
					<p className="smaller-text-size">You have earned <strong>3hrs 15min</strong> free parking at:<br />
					<a className="free-chosen-park" href="#">457 Franklin St. Adelaide</a></p>
					<p className="smaller-text-size">The value of free parking earnt will automatically be deducted from the total parking fee.</p>
				</Grid>
			</Grid>
			
		</Container>
		<Container maxWidth="lg" className="buttonWrapperSticky">
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Button className={classes.nvpButton} variant="contained" color="secondary" size="large" disableElevation>Done</Button>
				</Grid>
			</Grid>
		</Container>
		</div>
		<div id="footer-wrapper">
			
			<div id="footer">
				<a className="footer-icon" id="footer-icon-home" href="#"></a>
				<a className="footer-icon" id="footer-icon-arrow-l" href="#"></a>
				<a className="footer-icon" id="footer-icon-arrow-r" href="#"></a>
				<a className="footer-icon" id="footer-icon-menu" href="#"></a>
			</div>
					
		</div>
    </>
}
