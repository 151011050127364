import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import verifyMobileIcon from '../assets/svg/icon-verify-mobile-white.svg';


const useStyles = makeStyles((theme) => ({
	  root: {
		flexGrow: 1,
	  },
		nvpButton: {
			fontFamily: 'Nunito Sans',
			fontWeight: 'bold',
			textTransform: 'none',
			color: 'white',
			fontSize: '1.063rem',
		},
		verifyMobileButton: {
			backgroundImage: `url(${verifyMobileIcon})`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: '10% center',
			backgroundSize: '9%',
			backgroundColor: '#FD5A5F!important',
			borderColor: '#FD5A5F!important',
			color: '#FD5A5F',
			marginTop: '9px!important',
			padding: '8px!important',
			fontSize: '1rem!important',
			textIndent: '20px',
		},
		gridNoBottomMargin: {
			  paddingBottom: '0 !important',
		},
		gridNoTopMargin: {
			paddingTop: '0 !important',
		},
		wrapperMargin: {
			paddingTop: '20px',
		},
	}));

export default function CenteredGrid() {
      const classes = useStyles();
    return <>
		<div id="header-wrapper">
		  <div id="header">
				<h1 className="icon-h1 sign-up">Security</h1>
				<a id="valido-logo" href="#"></a>
		   </div>
		 </div>
		<div id="main">
    	<Container maxWidth="lg">
			<Grid container spacing={2}>
				<Grid item xs={12} style={{ paddingTop: '10px' }}>
					<h2 className="icon-h2 security-update">Security Update</h2>
					<TextField inputProps={{ className: "nvpInput" }} id="outlined-basic" label="Old Password" defaultValue="* * * * * * * * *" InputLabelProps={{ className: "nvpLabel" }} variant="outlined" margin="dense" fullWidth />
				</Grid>
				<Grid item xs={12} style={{ paddingTop: '12px' }}>
					<TextField inputProps={{ className: "nvpInput" }} id="outlined-basic" label="New Password" defaultValue="* * * * * * * * *" InputLabelProps={{ className: "nvpLabel" }} variant="outlined" margin="dense" fullWidth />
				</Grid>
				<Grid item xs={12} style={{ paddingTop: '12px' }}>
					<TextField inputProps={{ className: "nvpInput" }} id="outlined-basic" label="Confirm Password" defaultValue="* * * * * * * * *" InputLabelProps={{ className: "nvpLabel" }} variant="outlined" margin="dense" fullWidth />
				</Grid>
				<Grid item xs={6} style={{ paddingTop: '12px' }}>
					<TextField inputProps={{ className: "nvpInput" }} id="outlined-basic" label="Mobile" defaultValue="041* *** 723" InputLabelProps={{ className: "nvpLabel" }} variant="outlined" margin="dense" fullWidth />
				</Grid>
				<Grid item xs={6} style={{ paddingTop: '12px' }}>
					<Button className={classes.verifyMobileButton} variant="contained" color="secondary" size="large" disabled disableElevation>Verify Mobile</Button>
				</Grid>
				<Grid item xs={12}>
					<p style={{ textAlign: 'left' , paddingTop: '10px' , paddingBottom: '10px' }} className="fix-mobile-wrap">Enter the verification code sent to your mobile phone</p>
				</Grid>
				<Grid item xs={10} style={{ paddingTop: '0px' }}>
					<TextField inputProps={{ className: "nvpInput" }} id="outlined-basic" label="Verification Code" defaultValue="0 1 2 3 4 5 6" InputLabelProps={{ className: "nvpLabel" }} variant="outlined" margin="dense" fullWidth />
				</Grid>
				<Grid item xs={2} style={{ paddingTop: '0px' }}>
					<p className="forgot-password-right" style={{ paddingTop: '20px' }}><a href="#">Resend</a></p>
				</Grid>
			</Grid>
		</Container>
		<Container maxWidth="lg" className="buttonWrapperSticky">
			<Grid container spacing={2}>
				<Grid item xs={6}>
					<Button className={classes.nvpButton} variant="contained" color="secondary" size="large" disableElevation>Cancel</Button>
				</Grid>
				<Grid item xs={6}>
					<Button className={classes.nvpButton} variant="contained" color="secondary" size="large" disableElevation>Next</Button>
				</Grid>
			</Grid>
		</Container>
		</div>
		<div id="footer-wrapper">
			
			<div id="footer">
				<a className="footer-icon" id="footer-icon-home" href="#"></a>
				<a className="footer-icon" id="footer-icon-arrow-l" href="#"></a>
				<a className="footer-icon" id="footer-icon-arrow-r" href="#"></a>
				<a className="footer-icon" id="footer-icon-menu" href="#"></a>
			</div>
					
		</div>
    </>
}
