import makeStyles from '@mui/styles/makeStyles';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import offerCloseIcon from '../assets/svg/modal-close-icon-grey.svg';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import imageJBhifi from '../assets/images/vendor-image-jbhifi.jpg';
import logoJBhifi from '../assets/images/vendor-logo-jbhifi.jpg';


const useStyles = makeStyles((theme) => ({
	  root: {
		flexGrow: 1,
	  },
	  gridNoMargin: {
		  paddingTop: '0 !important',
		  paddingBottom: '0 !important',
	  },
		gridNoBottomMargin: {
			  paddingBottom: '0 !important',
		},
		gridNoTopMargin: {
			paddingTop: '0 !important',
		},
		wrapperMargin: {
			paddingTop: '20px',
		},
		overlayBG: {
			width: '100vw',
			height: '100vh',
			spacing: 0,
			justify: 'space-around',
			backgroundColor: '#4b4b4b',
			display: 'flex',
			flexDirection: 'column',
			padding: '0',
		},
		parkingOffersWrapper: {
			position: 'absolute',
			top: '90px',
			paddingTop: '40px',
			paddingLeft: '16px',
			paddingRight: '16px',
		}
	}));

export default function CenteredGrid() {
      const classes = useStyles();
    return <>
			<Container maxWidth="lg" disableGutters className={classes.overlayBG}>
				<Box bgcolor="white" className={classes.parkingOffersWrapper}>
					<Grid container spacing={2}>
						<Grid item xs={12} className={classes.gridNoMargin}>
							<a className="offer-close-button" href="#"><img className="offer-close-icon" src={offerCloseIcon} alt="Close" /></a>
							<img className="vendor-logo" src={logoJBhifi} alt="JB HiFi Logo" />
							<h2 className="vendor-title">JB Hifi</h2>
							<h4 className="vendor-location">Rundle Mall Plaza</h4>
							<img className="vendor-image" src={imageJBhifi} alt="JB HiFi Image" />
							<p className="vendor-description">JB Hi-Fi is Australia's largest home entertainment retailer with top products, great quality + value. Learn more about our product range online.</p>
						</Grid>
						<Grid item xs={12} className={classes.gridNoMargin}>
							<h2 className="icon-h2 parking-offers">Parking Offers</h2>
						</Grid>
						<Grid item xs={4} className={classes.gridNoTopMargin}>
							<TextField  inputProps={{ className: "nvpInput" }} id="select" value="State" select variant="outlined" margin="dense" fullWidth>
								<MenuItem value="State">State</MenuItem>
								<MenuItem value="SA">SA</MenuItem>
								<MenuItem value="VIC">VIC</MenuItem>
								<MenuItem value="NSW">NSW</MenuItem>
								<MenuItem value="QLD">QLD</MenuItem>
								<MenuItem value="WA">WA</MenuItem>
								<MenuItem value="NT">NT</MenuItem>
								<MenuItem value="TAS">TAS</MenuItem>
							 </TextField>
						</Grid>
						<Grid item xs={8} className={classes.gridNoTopMargin}>
							<TextField  inputProps={{ className: "nvpInput" }} id="select" value="Carpark" select variant="outlined" margin="dense" fullWidth>
								<MenuItem value="Carpark">Carpark</MenuItem>
								<MenuItem value="457 Franklin St">457 Franklin St</MenuItem>
								<MenuItem value="Adelaide Airport">Adelaide Airport</MenuItem>
							 </TextField>
						</Grid>
						<Grid item xs={12}>
							<ul className="ul-parking-offers">
								<li>Receive 20 min parking when you spend $50</li>
								<li>Receive 1hr parking when you spend $100</li>
								<li>Receive 1.5hr parking when you spend $150</li>
								<li>Receive 2hr parking when you spend $200</li>
							</ul>
						</Grid>
					</Grid>
				</Box>
			</Container>
    </>
}
