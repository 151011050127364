import React from 'react';
import './App.css';
import { createTheme } from '@mui/material/styles'
import { ThemeProvider } from '@mui/material/styles';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import One from "./pages/One";
import Two from "./pages/Two";
import Three from "./pages/Three";
import Four from "./pages/Four";
import Five from "./pages/Five";
import Six from "./pages/Six";
import Seven from "./pages/Seven";
import Eight from "./pages/Eight";
import Nine from "./pages/Nine";
import Ten from "./pages/Ten";
import Eleven from "./pages/Eleven";
import Twelve from "./pages/Twelve";
import Thirteen from "./pages/Thirteen";
import Fourteen from "./pages/Fourteen";
import Fifteen from "./pages/Fifteen";
import Sixteen from "./pages/Sixteen";
import Seventeen from "./pages/Seventeen";
import Eighteen from "./pages/Eighteen";
import Nineteen from "./pages/Nineteen";
import Twenty from "./pages/Twenty";
import Twentyone from "./pages/Twentyone";
import Twentytwo from "./pages/Twentytwo";
import Twentythree from "./pages/Twentythree";
import Twentyfour from "./pages/Twentyfour";
import Twentyfive from "./pages/Twentyfive";
import Twentysix from "./pages/Twentysix";
import Twentyseven from "./pages/Twentyseven";
import Twentyeight from "./pages/Twentyeight";
import Twentynine from "./pages/Twentynine";
import Thirty from "./pages/Thirty";
import Thirtyone from "./pages/Thirtyone";
import Thirtytwo from "./pages/Thirtytwo";
import Thirtythree from "./pages/Thirtythree";
import Thirtyfour from "./pages/Thirtyfour";
import Thirtyfive from "./pages/Thirtyfive";
import Thirtysix from "./pages/Thirtysix";
import Thirtyseven from "./pages/Thirtyseven";
import Thirtyeight from "./pages/Thirtyeight";
import Thirtynine from "./pages/Thirtynine";
import Forty from "./pages/Forty";
import Fortyone from "./pages/Fortyone";
import Fortytwo from "./pages/Fortytwo";
import Fortythree from "./pages/Fortythree";
import Fortyfour from "./pages/Fortyfour";
import Fortyfive from "./pages/Fortyfive";
import Fortysix from "./pages/Fortysix";
import Fortyseven from "./pages/Fortyseven";
import Fortyeight from "./pages/Fortyeight";
import Fortynine from "./pages/Fortynine";
import Fifty from "./pages/Fifty";
import Fiftyone from "./pages/Fiftyone";
import Fiftytwo from "./pages/Fiftytwo";
import Fiftythree from "./pages/Fiftythree";
import Fiftyfour from "./pages/Fiftyfour";
import Fiftyfive from "./pages/Fiftyfive";
import Fiftysix from "./pages/Fiftysix";
import Fiftyseven from "./pages/Fiftyseven";
import Fiftyeight from "./pages/Fiftyeight";
import Fiftynine from "./pages/Fiftynine";
import Sixty from "./pages/Sixty";
import Sixtyone from "./pages/Sixtyone";
import Sixtytwo from "./pages/Sixtytwo";
import Sixtythree from "./pages/Sixtythree";
import Sixtyfour from "./pages/Sixtyfour";
import Sixtyfive from "./pages/Sixtyfive";
import Sixtysix from "./pages/Sixtysix";
import Sixtyseven from "./pages/Sixtyseven";
import Sixtyeight from "./pages/Sixtyeight";
import Sixtynine from "./pages/Sixtynine";
import Seventy from "./pages/Seventy";
import Seventyone from "./pages/Seventyone";
import Seventytwo from "./pages/Seventytwo";
import Seventythree from "./pages/Seventythree";
import Seventyfour from "./pages/Seventyfour";
import Seventyfive from "./pages/Seventyfive";
import Seventysix from "./pages/Seventysix";
import Seventyseven from "./pages/Seventyseven";
import Seventyeight from "./pages/Seventyeight";
import Seventynine from "./pages/Seventynine";
import Eighty from "./pages/Eighty";
import Eightyone from "./pages/Eightyone";
import Eightytwo from "./pages/Eightytwo";
import Eightythree from "./pages/Eightythree";
import Eightyfour from "./pages/Eightyfour";
import Eightyfive from "./pages/Eightyfive";
import Eightysix from "./pages/Eightysix";
import Eightyseven from "./pages/Eightyseven";
import Eightyeight from "./pages/Eightyeight";
import Eightynine from "./pages/Eightynine";
import Ninety from "./pages/Ninety";
import Ninetyone from "./pages/Ninetyone";
import Ninetytwo from "./pages/Ninetytwo";
import Ninetythree from "./pages/Ninetythree";
import Ninetyfour from "./pages/Ninetyfour";
import Ninetyfive from "./pages/Ninetyfive";
import Ninetysix from "./pages/Ninetysix";
import Ninetyseven from "./pages/Ninetyseven";
import Ninetyeight from "./pages/Ninetyeight";
import Ninetynine from "./pages/Ninetynine";
import Onehundred from "./pages/Onehundred";
import Onehundredone from "./pages/Onehundredone";
import Onehundredtwo from "./pages/Onehundredtwo";
import Onehundredthree from "./pages/Onehundredthree";
import Onehundredfour from "./pages/Onehundredfour";
import Onehundredfive from "./pages/Onehundredfive";
import Onehundredsix from "./pages/Onehundredsix";
import Onehundredseven from "./pages/Onehundredseven";
import Onehundredeight from "./pages/Onehundredeight";
import Home from "./pages/Home";


const theme = createTheme({
  palette: {
    primary: {
      light: 'CDB3DC',
      main: '#607C96',
      dark: '#365F84',
    },
    secondary: {
		  main: '#fd5a5f',
    },
    background: {
	  default: '#ffffff',
	},
    action: {
      disabledBackground: '#f8d7d8',
      disabled: 'white'
    }
  },
  shape: {
    borderRadius: 6,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '9px',
          color: '#ffffff',
          textTransform: 'none',
          fontSize: '1.1rem',
          fontFamily: 'Nunito Sans',
          fontWeight: '700',
          letterSpacing: '0',
          '&.Mui-disabled': {
            color: '#ffffff',
          },
          '&.MuiButton-outlined': {
            color: '#fd5a5f',
          },
        },
      },
    },
  },
});

function App() {
  return (
<ThemeProvider theme={theme}>
      <Router>
        <div>
          
          {/* A <Routes> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
          <Routes>
            <Route path="/one" element={<One />} />
            <Route path="/two" element={<Two />} />
            <Route path="/three" element={<Three />} />
            <Route path="/four" element={<Four />} />
            <Route path="/five" element={<Five />} />
            <Route path="/six" element={<Six />} />
            <Route path="/seven" element={<Seven />} />
            <Route path="/eight" element={<Eight />} />
            <Route path="/nine" element={<Nine />} />
            <Route path="/ten" element={<Ten />} />
            <Route path="/eleven" element={<Eleven />} />
            <Route path="/twelve" element={<Twelve />} />
            <Route path="/thirteen" element={<Thirteen />} />
            <Route path="/fourteen" element={<Fourteen />} />
            <Route path="/fifteen" element={<Fifteen />} />
            <Route path="/sixteen" element={<Sixteen />} />
            <Route path="/seventeen" element={<Seventeen />} />
            <Route path="/eighteen" element={<Eighteen />} />
            <Route path="/nineteen" element={<Nineteen />} />
            <Route path="/twenty" element={<Twenty />} />
            <Route path="/twentyone" element={<Twentyone />} />
            <Route path="/twentytwo" element={<Twentytwo />} />
            <Route path="/twentythree" element={<Twentythree />} />
            <Route path="/twentyfour" element={<Twentyfour />} />
            <Route path="/twentyfive" element={<Twentyfive />} />
            <Route path="/twentysix" element={<Twentysix />} />
            <Route path="/twentyseven" element={<Twentyseven />} />
            <Route path="/twentyeight" element={<Twentyeight />} />
            <Route path="/twentynine" element={<Twentynine />} />
            <Route path="/thirty" element={<Thirty />} />
            <Route path="/thirtyone" element={<Thirtyone />} />
            <Route path="/thirtytwo" element={<Thirtytwo />} />
            <Route path="/thirtythree" element={<Thirtythree />} />
            <Route path="/thirtyfour" element={<Thirtyfour />} />
            <Route path="/thirtyfive" element={<Thirtyfive />} />
            <Route path="/thirtysix" element={<Thirtysix />} />
            <Route path="/thirtyseven" element={<Thirtyseven />} />
            <Route path="/thirtyeight" element={<Thirtyeight />} />
            <Route path="/thirtynine" element={<Thirtynine />} />
            <Route path="/forty" element={<Forty />} />
            <Route path="/fortyone" element={<Fortyone />} />
            <Route path="/fortytwo" element={<Fortytwo />} />
            <Route path="/fortythree" element={<Fortythree />} />
            <Route path="/fortyfour" element={<Fortyfour />} />
            <Route path="/fortyfive" element={<Fortyfive />} />
            <Route path="/fortysix" element={<Fortysix />} />
            <Route path="/fortyseven" element={<Fortyseven />} />
            <Route path="/fortyeight" element={<Fortyeight />} />
            <Route path="/fortynine" element={<Fortynine />} />
            <Route path="/fifty" element={<Fifty />} />
            <Route path="/fiftyone" element={<Fiftyone />} />
            <Route path="/fiftytwo" element={<Fiftytwo />} />
            <Route path="/fiftythree" element={<Fiftythree />} />
            <Route path="/fiftyfour" element={<Fiftyfour />} />
            <Route path="/fiftyfive" element={<Fiftyfive />} />
            <Route path="/fiftysix" element={<Fiftysix />} />
            <Route path="/fiftyseven" element={<Fiftyseven />} />
            <Route path="/fiftyeight" element={<Fiftyeight />} />
            <Route path="/fiftynine" element={<Fiftynine />} />
            <Route path="/sixty" element={<Sixty />} />
            <Route path="/sixtyone" element={<Sixtyone />} />
            <Route path="/sixtytwo" element={<Sixtytwo />} />
            <Route path="/sixtythree" element={<Sixtythree />} />
            <Route path="/sixtyfour" element={<Sixtyfour />} />
            <Route path="/sixtyfive" element={<Sixtyfive />} />
            <Route path="/sixtysix" element={<Sixtysix />} />
            <Route path="/sixtyseven" element={<Sixtyseven />} />
            <Route path="/sixtyeight" element={<Sixtyeight />} />
            <Route path="/sixtynine" element={<Sixtynine />} />
            <Route path="/seventy" element={<Seventy />} />
            <Route path="/seventyone" element={<Seventyone />} />
            <Route path="/seventytwo" element={<Seventytwo />} />
            <Route path="/seventythree" element={<Seventythree />} />
            <Route path="/seventyfour" element={<Seventyfour />} />
            <Route path="/seventyfive" element={<Seventyfive />} />
            <Route path="/seventysix" element={<Seventysix />} />
            <Route path="/seventyseven" element={<Seventyseven />} />
            <Route path="/seventyeight" element={<Seventyeight />} />
            <Route path="/seventynine" element={<Seventynine />} />
            <Route path="/eighty" element={<Eighty />} />
            <Route path="/eightyone" element={<Eightyone />} />
            <Route path="/eightytwo" element={<Eightytwo />} />
            <Route path="/eightythree" element={<Eightythree />} />
            <Route path="/eightyfour" element={<Eightyfour />} />
            <Route path="/eightyfive" element={<Eightyfive />} />
            <Route path="/eightysix" element={<Eightysix />} />
            <Route path="/eightyseven" element={<Eightyseven />} />
            <Route path="/eightyeight" element={<Eightyeight />} />
            <Route path="/eightynine" element={<Eightynine />} />
            <Route path="/ninety" element={<Ninety />} />
            <Route path="/ninetyone" element={<Ninetyone />} />
            <Route path="/ninetytwo" element={<Ninetytwo />} />
            <Route path="/ninetythree" element={<Ninetythree />} />
            <Route path="/ninetyfour" element={<Ninetyfour />} />
            <Route path="/ninetyfive" element={<Ninetyfive />} />
            <Route path="/ninetysix" element={<Ninetysix />} />
            <Route path="/ninetyseven" element={<Ninetyseven />} />
            <Route path="/ninetyeight" element={<Ninetyeight />} />
            <Route path="/ninetynine" element={<Ninetynine />} />
            <Route path="/onehundred" element={<Onehundred />} />
            <Route path="/onehundredone" element={<Onehundredone />} />
            <Route path="/onehundredtwo" element={<Onehundredtwo />} />
            <Route path="/onehundredthree" element={<Onehundredthree />} />
            <Route path="/onehundredfour" element={<Onehundredfour />} />
            <Route path="/onehundredfive" element={<Onehundredfive />} />
            <Route path="/onehundredsix" element={<Onehundredsix />} />
            <Route path="/onehundredseven" element={<Onehundredseven />} />
              <Route path="/onehundredeight" element={<Onehundredeight />} />
            <Route path="/" element={<Home />} />
          </Routes>
        </div>
      </Router>
      </ThemeProvider>
  );
}

export default App;