import makeStyles from '@mui/styles/makeStyles';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import offerCloseIcon from '../assets/svg/modal-close-icon-grey.svg';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import imageEBGames from '../assets/images/vendor-image-ebgames.jpg';
import logoEBGames from '../assets/images/vendor-logo-ebgames.jpg';


const useStyles = makeStyles((theme) => ({
	  root: {
		flexGrow: 1,
	  },
	  gridNoMargin: {
		  paddingTop: '0 !important',
		  paddingBottom: '0 !important',
	  },
		gridNoBottomMargin: {
			  paddingBottom: '0 !important',
		},
		gridNoTopMargin: {
			paddingTop: '0 !important',
		},
		wrapperMargin: {
			paddingTop: '20px',
		},
		overlayBG: {
			width: '100vw',
			height: '100vh',
			spacing: 0,
			justify: 'space-around',
			backgroundColor: '#4b4b4b',
			display: 'flex',
			flexDirection: 'column',
			padding: '0',
		},
		parkingOffersWrapper: {
			position: 'absolute',
			top: '90px',
			paddingTop: '40px',
			paddingLeft: '16px',
			paddingRight: '16px',
		}
	}));

export default function CenteredGrid() {
      const classes = useStyles();
    return <>
			<Container maxWidth="lg" disableGutters className={classes.overlayBG}>
				<Box bgcolor="white" className={classes.parkingOffersWrapper}>
					<Grid container spacing={2}>
						<Grid item xs={12} className={classes.gridNoMargin}>
							<a className="offer-close-button" href="#"><img className="offer-close-icon" src={offerCloseIcon} alt="Close" /></a>
							<img className="vendor-logo" src={logoEBGames} alt="EB Games Logo" />
							<h2 className="vendor-title">EB Games</h2>
							<h4 className="vendor-location">340 Locations</h4>
							<img className="vendor-image" src={imageEBGames} alt="Myer Image" />
							<p className="vendor-description">EB Games Australia is the ultimate place for video games. Shop thousands of amazing products in store now.</p>
						</Grid>
						<Grid item xs={12} className={classes.gridNoMargin}>
							<h2 className="icon-h2 parking-offers">Parking Offers</h2>
						</Grid>
						<Grid item xs={12} style={{ paddingTop: '5px' }}>
							<ul className="ul-parking-offers">
								<li>Receive 20 min parking when you spend $50</li>
								<li>Receive 1hr parking when you spend $100</li>
								<li>Receive 1.5hr parking when you spend $150</li>
								<li>Receive 2hr parking when you spend $200</li>
							</ul>
						</Grid>
					</Grid>
				</Box>
			</Container>
    </>
}
