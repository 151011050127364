import makeStyles from '@mui/styles/makeStyles';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import menuCloseIcon from '../assets/svg/modal-close-icon-white.svg';
import userAvatar from '../assets/images/profile-tony-roberts.jpg';


const useStyles = makeStyles((theme) => ({
		root: {
			flexGrow: 1,
		},
		nvpButton: {
			fontFamily: 'Nunito Sans',
			fontWeight: 'bold',
			textTransform: 'none',
			color: 'white',
			fontSize: '1.063rem',
		},
		gridNoMargin: {
			paddingTop: '0 !important',
			paddingBottom: '0 !important',
		},
		gridNoBottomMargin: {
			paddingBottom: '0 !important',
		},
		gridNoTopMargin: {
			paddingTop: '0 !important',
		},
		gridTopMargin: {
			paddingTop: '10px !important',
		},
		wrapperMargin: {
			paddingTop: '20px',
		},
		drawBG: {
			width: '100vw',
			height: '100vh',
			spacing: '0',
			justify: 'space-around',
			backgroundColor: '#6a7071',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			marginTop: '16px',
		},
	}));

export default function CenteredGrid() {
	  const classes = useStyles();
	return <>
			<Container maxWidth="lg" className={classes.drawBG}>
				<Grid container spacing={2} className="menu-slidein-wrapper">
					<Grid item xs={12} style={{ paddingTop: '20px' }}>
						<a className="menu-close-button" href="#"><img className="menu-close-icon" src={menuCloseIcon} alt="Close" /></a>
					</Grid>
					<Grid item xs={12} style={{ paddingTop: '45px' }}>
						<img className="user-avatar" src={userAvatar} alt="Tony Roberts" />
						<h3 className="welcome-user">Welcome,</h3>
						<h2 className="user-nicename">Tony Roberts</h2>
					</Grid>
					<Grid item xs={12} style={{ paddingTop: '5px' }}>
						<ul className="user-loggedin-menu">
							<li className="menu-link-home"><a href="#">Home</a></li>
							<li className="menu-link-my-activity"><a href="#">My Activity</a></li>
							<li className="menu-link-my-profile"><a href="#">My Profile</a></li>
							<li className="menu-link-my-vehicles"><a href="#">My Vehicles</a></li>
							<li className="menu-link-my-cards"><a href="#">My Cards</a></li>
							<li className="menu-link-my-parks"><a href="#">My Carparks</a></li>
							<li className="menu-link-security"><a href="#">Security</a></li>
							<li className="menu-link-forget-me"><a href="#">Forget Me</a></li>
							<li className="menu-link-signout"><a href="#">Sign Out</a></li>
						</ul>
					</Grid>
				</Grid>
			</Container>
	</>
}
